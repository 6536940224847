import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper, Button, Paper } from "@mui/material";
import Webpages from "../Tabs/Webpages";
import Documents from "../Tabs/Documents";
import VoiceChat from "./VoiceChat";
import Playground from "./Playground";
import "./steps.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import tick from "../../assets/alldone.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { resetcreateBotState } from "../../redux/action/createBotActions";
import { resetuploadDocsState } from "../../redux/action/uploadDocsActions";
import { resetuploadLinkState } from "../../redux/action/webpageAction";

const Steps = () => {
  const location = useLocation();
  const { state: cardState } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { data: uploadLinkData, loading: uploadLinkLoading } = useSelector(
    (state) => state.webpageUploadLink
  );

  const { data: uploadDocsData } = useSelector((state) => state.uploadDocsData);

  const steps = [
    "WEBPAGES",
    "UPLOAD DOCUMENT",
    "PLAYGROUND",
    // "CHATBOT VISIBILITY",
    // "PUBLISH CHATBOT",
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep >= steps.length) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderForm = (step) => {
    switch (step) {
      case 0:
        return uploadLinkLoading ? (
          <Playground />
        ) : (
          <Webpages handleNext={handleNext} />
        );
      case 1:
        return <Documents setActiveStep={setActiveStep} />;
      case 2:
        return <VoiceChat />;
      case 3:
        return;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (uploadLinkData?.status) {
      if (uploadLinkData?.status === 200) {
        // handleNext();
        setActiveStep(1);
      } else {
        setActiveStep(0);
      }
    }
    if (uploadDocsData.status) {
      if (uploadDocsData.status === 200) {
        setActiveStep(2);
      }
    }
  }, [uploadLinkData, uploadDocsData]);

  useEffect(() => {
    if (cardState?.cardClick === true) {
      setActiveStep(2);
    }
  }, []);

  const publishChatbot = () => {
    dispatch(resetcreateBotState());
    dispatch(resetuploadDocsState());
    dispatch(resetuploadLinkState());
    setActiveStep(0);

    setOpen(true);
  };
  const handleClose = () => {
    navigate("/allBots");
    setOpen(false);
  };

  const modalContent = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,

        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 2,
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h5>You are all done.</h5>
        <img src={tick} alt="" style={{ width: "200px" }}></img>
        {/* <Paper
          style={{
            backgroundColor: "#001a5c",
            padding: "2% 8%",
            borderRadius: "25px",
            color: "#ffffff",
          }}
        >
          PUBLISHED CHATBOT
        </Paper> */}
      </div>
    </Box>
  );
  return (
    <div className="path">
      <div className="stepper">
        {!cardState?.cardClick && (
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
      </div>

      <div className="tabContainer">{renderForm(activeStep)}</div>
      <div className="step-btn">
        {
          activeStep === steps.length - 1 && !cardState?.cardClick ? (
            <Button
              variant="contained"
              color="primary"
              onClick={publishChatbot}
              style={{ marginTop: "2%" }}
            >
              FINISH
            </Button>
          ) : (
            ""
          )
          // (
          //   <Button variant="contained" color="primary" onClick={handleNext}>
          //     Next
          //   </Button>
          // )
        }
      </div>
      <Modal open={open} onClose={handleClose}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Steps;
