import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { register } from "../../redux/apiCall/register";
import { startLogin } from "../../redux/apiCall/loginApi";
import Loader from "../../components/Loader";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export default function SignUpDialog({ open, setOpen, signInFlag }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: signupData, loading: signupLoading } = useSelector(
    (state) => state.registerDetails
  );

  const {
    flag: isUserLoggedIn,
    data: loginData,
    loading: loginLoading,
  } = useSelector((state) => state.loginDetails);

  const [userData, setUserData] = React.useState({
    name: "",
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSignUp = (value) => {
    if (value === "register") {
      dispatch(register(userData));
    } else {
      dispatch(startLogin(userData));
    }
  };

  React.useEffect(() => {
    if (isUserLoggedIn) {
      handleClose();
      navigate("/home");
    }
    if (signupData?.status === 200) {
      handleClose();
      setUserData({
        name: "",
        email: "",
        password: "",
      });
    }
  }, [isUserLoggedIn, navigate, signupLoading]);

  return (
    <div className="signup">
      {signInFlag ? (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle align="center">Sign Up</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              name="name"
              value={userData.name}
              onChange={handleInputChange}
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
            />
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
            />
          </DialogContent>

          <DialogActions>
            <LoadingButton
              variant="contained"
              className="btn-signup"
              loading={signupLoading}
              onClick={() => handleSignUp("register")}
            >
              Sign UP
            </LoadingButton>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle align="center">Sign In</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
            />
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
            />
          </DialogContent>

          <DialogActions>
            <LoadingButton
              variant="contained"
              className="btn-signup"
              loading={loginLoading}
              // loadingIndicator="Please Wait..."
              onClick={() => handleSignUp("signIn")}
            >
              Sign In
            </LoadingButton>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
