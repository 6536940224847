import React from "react";
import "../TermsConditions/termsconditions.scss";

const Policy = () => {
  return (
    <>
      <div className="terms-container">
        <h1 className="heading">Privacy Policy </h1>
        <div className="content-container">
          <p className="description">
            In the following, we inform you about the handling of your personal
            data. If you use our website and the services of our website,
            personal data will be processed.
          </p>
          <p className="description">
            Your privacy is important to us. Perpetualblock AG respects your
            privacy regarding any information that we may collect from you
            across our website, Perpetualblock, and other sites we own and
            operate.
          </p>
          <p className="description">
            This is our standard website privacy policy template, designed to
            aid compliance with data protection legislation, and now updated for
            the General Data Protection Regulation (GDPR) and the
            Bundesdatenschutzgesetzes Neu (BDSG-neu).
          </p>
          <p className="description">
            This template includes disclosures about the categories of personal
            information that are collected, website cookies, the ways in which
            that personal information may be used, the persons to whom the
            personal information may be disclosed, the security measures used to
            protect the personal information, and much else besides.
          </p>
          <p className="content-header">1. Information we collect</p>
          <p className="content-header">Log data</p>
          <p className="description">
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. This data is considered
            “non-identifying information”, as it does not personally identify
            you on its own. The log data includes:
          </p>
          <ul>
            <li>Information about your browser type and browser version</li>
            <li>The operating system you are using</li>
            <li>The website from which you are visiting us (referrer URL)</li>
            <li>Your IP address</li>
            <li>Date and time of your visit</li>
          </ul>
          <p className="content-header">Personal information</p>
          <p className="description">
            We may ask for personal information, such as your name and email
            address. This data is considered “identifying information”, as it
            can personally identify you. We only request personal information
            relevant to providing you with a service, and only use it to help
            provide or improve the service provided to you.
          </p>
          <p className="content-header">How we collect information</p>
          <p className="description">
            We collect information by fair and lawful means, with your knowledge
            and consent. We also let you know why we’re collecting it and how it
            will be used. You are free to refuse our request for this
            information, with the understanding that we may be unable to provide
            you with some of your desired services without it.
          </p>
          <p className="content-header">Use of information</p>
          <p className="description">
            We may use a combination of identifying and non-identifying
            information to understand who our visitors are, how they use our
            services, and how we may improve their experience of our website in
            future. We do not disclose the specifics of this information
            publicly, but may share aggregated and anonymized versions of this
            information, for example, in website and customer usage trend
            reports.
          </p>
          <p className="description">
            We may use your personal details to contact you with updates about
            our website and services, along with promotional content that we
            believe may be of interest to you. If you wish to opt out of
            receiving promotional content, you can follow the “unsubscribe”
            instructions provided alongside any promotional correspondence from
            us.
          </p>
          <p className="content-header">Data processing and storage</p>
          <p className="description">
            The personal information we collect is stored and processed where we
            or our partners, affiliates and third-party providers maintain
            facilities. We transfer data only within jurisdictions subject to
            data protection laws that reflect our commitment to protecting the
            privacy of our users.
          </p>
          <p className="description">
            We only retain personal information for as long as necessary to
            provide a service, or to improve our services in future. While we
            retain this data, we will protect it within commercially acceptable
            means to prevent loss and theft, as well as unauthorized access,
            disclosure, copying, use or modification. That said, we advise that
            no method of electronic transmission or storage is 100% secure, and
            cannot guarantee absolute data security.
          </p>
          <p className="description">
            The temporary processing of your IP address by our system is
            necessary to enable the provision of the website to your terminal
            device. Furthermore, we use your personal data to optimize our
            website and to ensure the security of our IT systems. Your data will
            not be used for marketing purposes. This also gives rise to our
            legitimate interest in data processing.
          </p>
          <p className="description">
            If you request your personal information to be deleted, or where
            your personal information becomes no longer relevant to our
            operations, we will erase it from our system within a reasonable
            timeframe.
          </p>{" "}
          <p className="description">
            The legal basis for the temporary processing of data and log files
            is Art. 6 (1)(f) of the GDPR.
          </p>
          <p className="content-header">Duration of storage</p>
          <p className="description">
            The personal data referred to above shall be deleted as soon as they
            are no longer necessary for the purpose of the processing. This is
            the case with the data processing for the provision of the website
            when you end the respective session.
          </p>
          <p className="description">
            If IP addresses are processed in log files, they are deleted after
            seven days. A longer storage period is only possible if your IP
            address is deleted or altered so that it is no longer possible to
            deduce your identity.
          </p>
          <p className="description">
            Possibility of objection and removal according to Art. 21 of the
            GDPR
          </p>
          <p className="description">
            It is not possible to object to this data processing, as the
            processing of the data is absolutely necessary for the provision of
            the website.
          </p>
          <p className="content-header">2. Cookies</p>
          <p className="content-header">
            a) Description and scope of data processing
          </p>
          <p className="description">
            We use so-called cookies on our website, which serve to recognize
            you as a user and make it easier for you to use our site. Cookies
            are small text files that your Internet browser installs on your
            terminal device. These are often so-called “session cookies”, which
            are deleted at the end of your session.
          </p>
          <p className="description">
            Other cookies remain installed on your end device until you remove
            them again. These installed cookies allow us to identify your web
            browser on your next visit.
          </p>
          <p className="description">
            You can also set your web browser to notify you when cookies are
            installed. Within the settings, you can choose whether cookies
            should only be allowed in individual cases, whether they should only
            be installed in certain cases, whether they should not be accepted
            in general or whether they should be deleted automatically after
            closing your web browser. If you decide to deactivate cookies, this
            may limit the usage of this website.
          </p>
          <p className="description">
            We use cookies on our website to make it more user-friendly for you.
            Some elements of our website require that the accessing browser can
            be identified even after a page change. <br></br>The following data
            is stored and transmitted in the cookies:
          </p>
          <ul>
            <li>Entered search terms</li>
            <li>Frequency of page views</li>
            <li>Use of website function</li>
          </ul>
          <p className="description">
            Your processed personal data will be pseudonymized as a technical
            precaution. It is therefore no longer possible to assign the data to
            any visiting user. The data will not be stored together with any
            other of your processed personal data.
          </p>
          <p className="description">
            When you access our website for the first time, an information
            banner informs you about the use of cookies for analytical purposes
            and refers you to this data protection declaration. In this context,
            there is also an indication of how the storage of cookies in the
            browser settings can be prevented.
          </p>
          <p className="content-header">b) Legal basis for data processing</p>
          <p className="description">
            The legal basis for the processing of your personal data using
            cookies is Art. 6 (1)(f) of the GDPR.
          </p>
          <p className="content-header">c) Purpose of data processing</p>
          <p className="description">
            We use technically necessary cookies to optimize your experience
            visiting the website. Otherwise, we would not be able to offer you
            some features of our website, as it is necessary that your web
            browser is recognizable once page change occurs.
          </p>
          <p className="description">
            The use of cookies is required for the following application:
          </p>
          <ul>
            <li>Adoption of language settings</li>
            <li>Remember search terms</li>
          </ul>
          <p className="description">
            We do not use the personal data collected by technically necessary
            cookies to create user profiles. The analysis cookies are used to
            improve the quality of our website and its content. They are also
            used to continually optimize our services and thus improve the user
            comfort of our website.
          </p>
          <p className="description">
            Our legitimate interest in the processing of your personal data also
            lies in these purposes.
          </p>
          <p className="content-header">
            d) Duration of storage, objection and removal options
          </p>
          <p className="description">
            Cookies are stored on the user’s computer and transmitted to our
            site by the user. Therefore, you as a user have full control over
            the use of cookies. You can deactivate or restrict the transmission
            of cookies by changing the settings in your Internet browser.
            Cookies that have already been saved can be deleted at any time.
            This can also be done automatically. If cookies are deactivated for
            our website, it is possible that some functions of the website can
            no longer be used to their full extent.
          </p>
          <p className="content-header">3. Types of cookies</p>
          <p className="content-header">Essential cookies</p>
          <p className="description">
            Essential cookies are crucial to your experience of a website,
            enabling core features like user logins, account management,
            shopping carts, and payment processing. We use essential cookies to
            enable certain functions on our website.
          </p>
          <p className="content-header">Performance cookies</p>
          <p className="description">
            Performance cookies are used in the tracking of how you use a
            website during your visit, without collecting personal information
            about you. Typically, this information is anonymous and aggregated
            with information tracked across all site users, to help companies
            understand visitor usage patterns, identify and diagnose problems or
            errors their users may encounter, and make better strategic
            decisions in improving their audience’s overall website experience.
            These cookies may be set by the website you’re visiting
            (first-party) or by third-party services. We use performance cookies
            on our site.
          </p>
          <p className="content-header">Functionality cookies</p>
          <p className="description">
            Functionality cookies are used in collecting information about your
            device and any settings you may configure on the website you’re
            visiting (like language and timezone settings). With this
            information, websites can provide you with customized, enhanced or
            optimized content and services. These cookies may be set by the
            website you’re visiting (first-party) or by third-party service. We
            use functionality cookies for selected features on our site.
          </p>
          <p className="content-header">Targeting/advertising cookies</p>
          <p className="description">
            Targeting/advertising cookies are used in determining what
            promotional content is more relevant and appropriate to you and your
            interests. Websites may use them to deliver targeted advertising or
            to limit the number of times you see an advertisement. This helps
            companies improve the effectiveness of their campaigns and the
            quality of content presented to you. These cookies may be set by the
            website you’re visiting (first-party) or by third-party services.
            Targeting/advertising cookies set by third-parties may be used to
            track you on other websites that use the same third-party service.
            We use targeting/advertising cookies on our site.
          </p>
          <p className="content-header">Third-party cookies on our site</p>
          <p className="description">
            We may employ third-party companies and individuals on our
            websites—for example, analytics providers and content partners. We
            grant these third parties access to selected information to perform
            specific tasks on our behalf. They may also set third-party cookies
            in order to deliver the services they are providing. Third-party
            cookies can be used to track you on other websites that use the same
            third-party service. As we have no control over third-party cookies,
            they are not covered by Perpetualblock AG’s cookie policy.
          </p>
          <p className="content-header">Our third-party privacy promise</p>
          <p className="description">
            We review the privacy policies of all our third-party providers
            before enlisting their services to ensure their practices align with
            ours. We will never knowingly include third-party services that
            compromise or violate the privacy of our users.
          </p>
          <p className="description">
            How you can control or opt-out of cookies
          </p>
          <p className="description">
            If you do not wish to accept cookies from us, you can instruct your
            browser to refuse cookies from our website. Most browsers are
            configured to accept cookies by default, but you can update these
            settings to either refuse cookies altogether, or to notify you when
            a website is trying to set or update a cookie.
          </p>
          <p className="description">
            If you browse websites from multiple devices, you may need to update
            your settings on each individual device.
          </p>
          <p className="description">
            Although some cookies can be blocked with little impact on your
            experience of a website, blocking all cookies may mean you are
            unable to access certain features and content across the sites you
            visit.
          </p>
          <p className="content-header">4. Newsletter</p>
          <p className="content-header">
            a) Description and scope of data processing
          </p>
          <p className="description">
            On our website we give you the option to subscribe to our free
            newsletter. In order for us to send it to you, we need your email
            address when you register. We also process the date and time of your
            registration. As part of the registration process, we will obtain
            your consent to the processing of your personal data and refer you
            to this data protection declaration.
          </p>
          <p className="content-header">b) Legal basis for data processing</p>
          <p className="description">
            The legal basis for the processing of personal data after
            registration for the newsletter is the existence of consent pursuant
            to Art. 6 (1)(a) of the GDPR.
          </p>
          <p className="content-header">c) Purpose of data processing</p>
          <p className="description">
            The purpose of processing your email address is to send you the
            newsletter. We check the email address you provide to see whether
            you are the actual owner or whether the owner of the email address
            has consented to receive the newsletter. We process your IP address
            and the date and time of your registration for our security in the
            event that a third party registers on our website without your
            knowledge or misuses your personal data.
          </p>
          <p className="content-header">d) Duration of storage</p>
          <p className="description">
            Personal data is deleted if it is no longer required for the purpose
            of its collection because you have unsubscribed from the
            subscription. Your email address will be deleted immediately, all
            other data collected during the registration process, such as the
            date and time of your registration, will be deleted within 90 days
            after a technical defined deletion cycle.
          </p>
          <p className="content-header">
            5. Use of Google Analytics with anonymization function
          </p>
          <p className="content-header">
            a) Description and scope of data processing
          </p>
          <p className="description">
            We use Google Analytics, a web analytics service provided by Google
            Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,
            hereinafter referred to as “Google”, on our site. Google Analytics
            uses so-called cookies. These are text files that are stored on your
            terminal device and enable an analysis of your website usage.
          </p>
          <p className="description">
            The information generated by this cookie regarding your usage
            behavior is transferred to the Google servers located in the USA and
            stored there.
          </p>
          <p className="description">
            I order that you can still use our website anonymously, we use
            Google Analytics with the addition “_gat._anonymizeIp”. As a result,
            your IP address is already reduced by Google within member states of
            the European Union or in other contracting states of the Agreement
            on the European Economic Area and is thus made anonymous. Only in
            very special cases is the full IP address transmitted to a Google
            server in the USA and shortened there. Under no circumstances will
            your IP address be merged with other data collected by Google.
          </p>
          <p className="description">
            More detailed information on the terms of use and data protection of
            Google Analytics can be found at
            https://support.google.com/analytics/answer/6004245?hl=en .
          </p>
          <p className="description">
            For the exceptional cases in which personal data is transferred to
            the USA, Google has submitted to the EU-US Privacy Shield,
            https://www.privacyshield.gov/EU-US-Framework, so that an
            appropriate level of data protection is guaranteed.
          </p>
          <p className="content-header">b) Legal basis for data processing</p>
          <p className="description">
            The legal basis for the use of Google Analytics is Art. 6 para. 1
            lit. f DSGVO.
          </p>
          <p className="content-header">c) Purpose of data processing</p>
          <p className="description">
            Google will use this information to determine how you have used the
            website, to provide us with a summary of website activity and to
            provide other services relating to website activity. Google may
            share the information collected with third parties where required to
            do so by law, or where Google uses third parties to process the
            information.
          </p>
          <p className="description">
            Our legitimate interest in the processing of your personal data also
            lies in these purposes.
          </p>
          <p className="content-header">d) Duration of storage</p>
          <p className="description">
            The cookies used by Google Analytics to analyze website usage have a
            preset storage period, which is neither known to us nor can be
            influenced. You can independently delete the cookies installed by
            Google Analytics and thus the stored data. We will explain to you in
            the following point how this deletion can be carried out via the
            browser settings.
          </p>
          <p className="content-header">
            e) Possibility of objection and removal according to Art. 21 of the
            GDPR
          </p>
          <p className="description">
            You may refuse the use of cookies by selecting the appropriate
            settings on your browser, however please note that if you do this
            you may not be able to use the full functionality of this websit
          </p>
          <p className="description">
            Google offers a deactivation add-on for the most common browsers,
            which gives you more control over which data Google collects about
            the websites you visit. The add-on informs the JavaScript (ga.js) of
            Google Analytics that no information about the website visit should
            be transmitted to Google Analytics. However, the deactivation add-on
            for Google Analytics browsers does not prevent information from
            being transmitted to us or to other web analysis services we may
            use. For further information on installing the Browser Add-on,
            please click on the link below:
            https://tools.google.com/dlpage/gaoptout?hl=en
          </p>
          <p className="content-header">6. Newsletter tracking</p>
          <p className="content-header">
            a) Description and scope of data processing
          </p>
          <p className="description">
            So-called “web beacons” (tracking pixels) are integrated into our
            email newsletter. This is a pixel-sized image file which, when the
            newsletter is opened, collects and sends certain data to a server
            such as the time of retrieval, your IP address, details of the email
            program used and which link you followed. The name of the image file
            is individualized for each newsletter recipient by attaching a
            unique ID. This enables us to register to which email address which
            ID belongs and serves to determine which newsletter recipient has
            just opened or is reading the email.
          </p>
          <p className="content-header">b) Legal basis for data processing</p>
          <p className="description">
            The legal basis for the use of “web beacons” is Art. 6 (1)(f) of the
            GDPR.
          </p>
          <p className="content-header">c) Purpose of data processing</p>
          <p className="description">
            The tracking pixels allow us to see when an email was opened and
            which link the recipient followed. We use this information to
            continuously improve our newsletters and to adapt them to your
            personal wishes and needs. Our legitimate interest in the processing
            of your personal data also lies in these purposes.
          </p>
          <p className="content-header">d) Duration of storage</p>
          <p className="description">
            The data stored within the scope of the newsletter tracking will be
            deleted within 90 days after a technical defined deletion cycle.
          </p>
          <p className="content-header">
            e) Possibility of objection and removal according to Art. 21 of the
            GDPR
          </p>
          <p className="description">
            You have the right to object to the use of “web-beacons” at any
            time.
          </p>
          <p className="content-header">7. Use of social media buttons</p>
          <p className="description">
            Our website uses social plugins of the following social networks:
          </p>
          <p className="description">
            Facebook, which is operated by Facebook Inc., 1601 S. California
            Ave, Palo Alto, CA 94304, USA; Twitter, which is operated by Twitter
            Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA;
            google+, which is operated by Google Inc., 1600 Amphitheater
            Parkway, Mountain View, CA 94043, USA; LinkedIn, which is operated
            by LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA
            94043, USA; XING, which is operated by Xing AG, Gänsemarkt 43, 20354
            Hamburg, Germany.
          </p>
          <p className="description">
            These social plugins are marked with the respective logos, so-called
            placeholders. Only when you click on these placeholders will they be
            activated and establish a direct connection via your browser to the
            servers of Facebook, Twitter or Google+, Xing or LinkedIn.
            Information is transmitted to Facebook, Twitter, Google+, Xing or
            LinkedIn that you have accessed the page of our website containing
            the social plugin. This also happens if you are not logged in to
            Facebook, Twitter, Google+, Xing and LinkedIn or do not have a
            corresponding account.
          </p>
          <p className="description">
            However, if you have an appropriate account and are logged in to
            Facebook, Twitter, Google+, Xing or LinkedIn at that time, your
            visit to our pages and any of your interactions in connection with
            the social plugins (e.g. creating a comment, etc.) may be assigned
            to your profile there and stored on Facebook, Twitter, Google+, Xing
            or LinkedIn.
          </p>
          <p className="description">
            The purpose and scope of the data collection as well as the
            processing and use of the data by Facebook and your related rights
            and setting options for the protection of your privacy can be found
            in the data protection information of Facebook
            (http://www.facebook.com/policy.php), Twitter
            (http://twitter.com/privacy), Google+
            (http://www.google.de/intl/de/policies/terms/regional.html),
            (http://www.xing.com/privacy) and
            (http://www.linkedin.com/legal/privacy-policy).
          </p>
          <p className="content-header">8. Third-party access to information</p>
          <p className="description">We use third-party services for:</p>
          <p className="description">
            Analytics tracking, User authentication, Advertising and promotion,
            Content marketing, Email marketing, Payment processing
          </p>
          <p className="description">
            These services may access our data solely for the purpose of
            performing specific tasks on our behalf. We do not share any
            personally identifying information with them without your explicit
            consent. We do not give them permission to disclose or use any of
            our data for any other purpose.
          </p>
          <p className="description">
            We may, from time to time, allow limited access to our data by
            external consultants and agencies for the purpose of analysis and
            service improvement. This access is only permitted for as long as
            necessary to perform a specific function. We only work with external
            agencies whose privacy policies align with ours.
          </p>
          <p className="description">
            We will refuse government and law enforcement requests for data if
            we believe a request is too broad or unrelated to its stated
            purpose. However, we may cooperate if we believe the requested
            information is necessary and appropriate to comply with legal
            process, to protect our own rights and property, to protect the
            safety of the public and any person, to prevent a crime, or to
            prevent what we reasonably believe to be illegal, legally
            actionable, or unethical activity.
          </p>
          <p className="description">
            We do not otherwise share or supply personal information to third
            parties. We do not sell or rent your personal information to
            marketers or third parties.
          </p>
          <p className="content-header">
            9. Your rights as a data subject according to the GDPR
          </p>
          <p className="description">
            If your personal data is processed by us, you are affected in the
            sense of the GDPR, so that you have the following rights towards us:
          </p>
          <p className="content-header">Right of information</p>
          <p className="description">
            You have the right to request information about your personal data
            processed by us at any time. This also includes information about
            the origin, recipients or categories of recipients to whom we
            transmit your data, as well as the purposes for which we process
            your personal data.
          </p>
          <p className="content-header">Right of correction</p>
          <p className="description">
            You have the right to ask us to correct and/or complete your
            personal data immediately if your personal data is incorrect or
            incomplete.
          </p>
          <p className="content-header">
            Right of deletion or restriction of processing
          </p>
          <p className="description">
            You can ask us to delete your personal data immediately. We are
            obliged to delete your personal data immediately. A different
            situation only applies if we are obliged to further process your
            personal data due to contractual and/or legal regulations. This is
            the case, for example, if we are prohibited by tax law from deleting
            your personal data. In such a case, we restrict the processing and
            delete the personal data in question immediately after expiry of the
            retention period.
          </p>
          <p className="content-header">Right of data transferability</p>
          <p className="description">
            You have the right to receive the personal data concerning you which
            you have provided to us in a structured, common and machine-readable
            format, insofar as this is technically possible. In addition, you
            have the right to transfer this data to another responsible person
            without our interference.
          </p>
          <p className="content-header">
            Automated decision in individual cases including profiling
          </p>
          <p className="description">
            You have the right not to be subject to any decision based solely on
            automated processing, including profiling, that has any legal effect
            on you or similarly significantly affects you.
          </p>
          <p className="content-header">
            Right of complaint to a supervisory authority
          </p>
          <p className="description">
            You are entitled to complain to the competent supervisory authority
            if you are of the opinion that the processing of your personal data
            violates the GDPR.
            {/* The responsible supervisory authority is: The
            Hessian Data Protection Officer, POB 3163, 65021 Wiesbaden, email:
            poststelle@datenschutz.hessen.de. */}
          </p>
          <p className="content-header">10. Children’s Privacy</p>
          <p className="description">
            We are committed to protecting the privacy of children who use our
            services. We encourage parents to engage with us in protecting their
            children’s privacy, ensuring a safe and enjoyable online experience.
            Please refer to our Children’s Privacy Policy for more information.
          </p>
          <p className="content-header">Children’s Privacy Policy</p>
          <p className="description">
            We are committed to protecting the privacy of children who use
            http://www.perpetualblock.com and its services. This children’s
            privacy policy is part of our approach to privacy, and covers the
            way we collect, store and manage information provided by children
            under the age of 13, in accordance with the US Children’s Online
            Privacy Protection Act (COPPA).
          </p>
          <p className="description">
            We encourage parents to engage with us in protecting their
            children’s privacy, ensuring a safe and enjoyable online experience.
          </p>
          <p className="content-header">Collecting information from children</p>
          <p className="description">
            At times, we may require information from children to enable
            participation in certain activities, for example, registering an
            account, customizing a profile, or receiving notifications. When
            collecting non-personal information, we encourage children never to
            provide any details that may personally identify them or reveal
            their location. We do not require children to provide more
            information than is necessary to participate in an activity.
          </p>
          <p className="description">
            We do not use children’s contact details for marketing purposes,
            though we may use them for our internal marketing and research in
            order to improve the quality of products and services offered across
            our site.
          </p>
          <p className="content-header">Parental consent</p>
          <p className="description">
            In accordance with COPPA, if an activity does require any personal
            information (such as first name, last name, or email address), we
            will provide notice to and seek consent from a parent or guardian
            prior to collecting the information. We only retain collected
            information for as long as necessary to enable participation in the
            requested activity.
          </p>
          <p className="description">
            In the event we discover we have collected personal information in a
            manner non-compliant with COPPA, we will either delete the
            information or seek parental consent.
          </p>
          <p className="description">
            As a parent/guardian, if you believe your child is participating in
            an activity that collects personal information, and you have not
            received a notification or request for consent, please feel free to
            get in touch via our contact page http://www.Perpetualblock.com. We
            do not use parent contact details for marketing purposes unless
            marketing materials are explicitly requested.
          </p>
          <p className="content-header">Safeguarding children’s privacy</p>
          <p className="description">
            We take security seriously, and do what we can within commercially
            acceptable means to protect your child’s personal information from
            loss or theft, as well as unauthorized access, disclosure, copying,
            use or modification. That said, we advise that no method of
            electronic transmission or storage is 100% secure, and cannot
            guarantee absolute data security.
          </p>
          <p className="content-header">
            Information abuse and community misconduct
          </p>
          <p className="description">
            We do not tolerate doxing (publishing of private or personal
            information about an individual without their consent),
            cyberbullying, or other forms of information abuse on <span> </span>
            <a href="https://www.perpetualblock.io/">Perpetualblock.</a>
          </p>
          <p className="description">
            If we discover that a child’s personal information has been
            disclosed on our site without the express consent of their
            parent/guardian, we will remove the content in question as quickly
            as possible and effect disciplinary measures (a warning, suspension
            or ban) on the offending account.
          </p>
          <p className="content-header">Third-party access to information</p>
          <p className="description">
            We do not knowingly disclose any personally identifying information
            or personal information provided by children to third parties. We
            may, however, disclose anonymized and aggregated versions of this
            information (analytics and statistics) for business, marketing or
            public relations purposes.
          </p>
          <p className="content-header">Parental controls and intervention</p>
          <p className="description">
            As a parent/guardian, you may at any time refuse to let us collect
            further information from your children for a particular activity or
            account. If you have given us consent previously, you may log in to
            your child’s account to review, edit or delete the information, or
            contact us via to request the removal of the information from our
            records.
          </p>
          <p className="description">
            Please be aware that the removal of certain information may result
            in the termination of the associated account, or withdrawal from the
            associated activity.
          </p>
          <p className="content-header">11. Limits of our policy</p>
          <p className="description">
            This privacy policy only covers Perpetualblock AG’s own collecting
            and handling of data. We only work with partners, affiliates and
            third-party providers whose privacy policies align with ours.
            However we do not accept responsibility or liability for their
            respective privacy practices.
          </p>
          <p className="description">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and do not accept responsibility or
            liability for their respective privacy practices.
          </p>
          <p className="content-header">12. Changes to this policy</p>
          <p className="description">
            At our discretion, we may change our privacy policy to reflect
            current acceptable practices. We will take reasonable steps to let
            users know about changes via our website. If you are a registered
            user on Perpetualblock, we will notify you using the contact details
            saved in your account. Your continued use of this site after any
            changes to this policy will be regarded as acceptance of our
            practices around privacy and personal information.
          </p>
          <p className="content-header">Your rights and responsibilities</p>
          <p className="description">
            As our user, you have the right to be informed about how your data
            is collected and used. You are entitled to know what data we collect
            about you, and how it is processed. You are entitled to correct and
            update any personal information about you, and to request this
            information be deleted. You may amend or remove your account
            information at any time, using the tools provided in your account
            control panel.
          </p>
          <p className="description">
            You are entitled to restrict or object to our use of your data,
            while retaining the right to use your personal information for your
            own purposes. You have the right to opt out of data about you being
            used in decisions based solely on automated processing.
          </p>
          <p className="description">
            Feel free to contact us if you have any concerns or questions about
            how we handle your data and personal information.
          </p>
          {/* <p className="description">
            Perpetualblock AG Data Controller <br></br>Abhijit Keskar<br></br>
            compliance@Perpetualblock.com
          </p>
          <p className="description">
            Perpetualblock AG Data Protection Officer <br></br> Olga Stepanova{" "}
            <br></br>
            compliance@Perpetualblock.com
          </p>
          <p className="description">
            Responsible office is Perpetualblock AG, Frankfurter Strasse 27, 65760
            Eschborn, Germany, phone: +49-6196-9677311, email:
            info@Perpetualblock.com, legally represented by the Management Board:
            Holger Hoffmann, Dr. Andreas Hochheimer, Prof. Dr. Dr. Juergen
            Scheele, Gaurav Tripathi
          </p> */}
          {/* <p className="description">
            This policy is effective as of 25 May 2018.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Policy;
