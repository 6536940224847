import React from "react";
import { getAuthHeader } from "../../utils/sessionManagement";
import { TextField } from "@mui/material";
import "./steps.scss";
import loader from "../../assets/searching.gif";

const Playground = () => {
  return (
    <div className="playground">
      <h5>Username: {getAuthHeader("Email")}</h5>
      <h5 style={{ color: "#001A5C" }}>Website :</h5>
      <TextField
        hiddenLabel
        id="filled-hidden-label-normal"
        value={sessionStorage.getItem("websiteurl")}
        className="website-textfield"
      />
      <p>
        Please wait while we are creating knowledgebase. It might take couple of
        minutes...
      </p>
      <img src={loader} alt=""></img>
    </div>
  );
};

export default Playground;
