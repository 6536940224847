// import { checkIfRegister } from "../../utils/sessionManagement";

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
  //   flag: checkIfRegister(),
};

const webpageReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "UPLOADLINK_SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.payload,
        flag: true,
      };

    case "UPLOADLINK_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "UPLOADLINK_RESET":
      return {
        ...defaultState,
      };
    case "UPLOADLINK_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: {},
      };
    default:
      return { ...state };
  }
};

export const webpageUploadLinkReducer = {
  webpageUploadLink: webpageReducer,
};
