import * as React from "react";
import PDFHighlighter from "./PDFHighlighter";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Modal } from "@mui/material";

const HighlightModal = ({ pdfUrl, open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "3%",
    left: "20%",
    width: "60%",
    height: "95%",
    overflow: "auto",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    color: "#fff",
    // opacity: ".5",
    boxShadow: 24,
    zIndex: "600",
    backdropFilter: "blur(100px)",
    "-webkit-backdrop-filter": "blur(10px)",
    borderRadius: "10px",
  };

  const closeButtonStyle = {
    width: "24px",
    position: "sticky",
    top: "10px",
    left: "96%",
    color: "red",
    cursor: "pointer",
    padding: "10px",
    fontSize: "28px",
    zIndex: 1000,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        backdropFilter: "blur(5px)",
      }}
    >
      <div style={style}>
        <span style={closeButtonStyle} onClick={() => handleClose()}>
          <Button variant="contained">
            <CloseIcon />
          </Button>
        </span>
        <div
          style={{
            zIndex: "1000",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            opacity: "1",
            overflow: "auto",
          }}
          className="checking"
        >
          <PDFHighlighter
            pdfUrl={pdfUrl}
            pageNumber={23}
            style={{ position: "static" }}
          />
          {/* <RenderHighlightAreas /> */}
        </div>
      </div>
    </Modal>
  );
};

export default HighlightModal;

// import * as React from "react";
// import PDFHighlighter from "./PDFHighlighter";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import { Button } from "@mui/material";
// // const style = {
// //   position: "absolute",
// //   top: "12%",
// //   width: "100%",
// //   height: "89%",
// //   overflow: "auto",
// //   backgroundColor: "rgba(255, 255, 255, 0.8)",
// //   color: "#fff",
// //   // opacity: ".5",
// //   boxShadow: 24,
// //   zIndex: "600",
// // };
// const style = {
//   position: "absolute",
//   top: "12%",
//   left: "25%",
//   width: "55%",
//   height: "79%",
//   overflow: "auto",
//   backgroundColor: "rgba(255, 255, 255, 0.8)",
//   color: "#fff",
//   // opacity: ".5",
//   boxShadow: 24,
//   zIndex: "600",
//   backdropFilter: "blur(100px)",
//   "-webkit-backdrop-filter": "blur(10px)",
//   borderRadius: "10px",
// };
// const closeButtonStyle = {
//   width: "24px",
//   position: "sticky",
//   top: "10px",
//   left: "96%",
//   color: "red",
//   cursor: "pointer",
//   padding: "10px",
//   fontSize: "28px",
//   zIndex: 1000,
// };
// export default function HighlightModal({ pdfUrl, open, handleClose }) {
//   return (
//     <>
//       <div
//         style={{
//           ...style,
//         }}
//       >
//         <span style={closeButtonStyle} onClick={() => handleClose()}>
//           <Button variant="contained">
//             <CloseIcon />
//           </Button>
//         </span>
//         <div
//           style={{
//             zIndex: "1000",
//             backgroundColor: "rgba(255, 255, 255, 0.8)",
//             opacity: "1",
//           }}
//           className="checking"
//         >
//           <PDFHighlighter pdfUrl={pdfUrl} style={{ position: "static" }} />
//         </div>
//       </div>
//     </>
//   );
// }
