// import { checkIfRegister } from "../../utils/sessionManagement";

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
  //   flag: checkIfRegister(),
};

const fetchBotsReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "FETCH_BOTS_SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.payload,
        flag: true,
      };

    case "FETCH_BOTS_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "FETCH_BOTS_RESET":
      return {
        ...state,
        loading: false,
        isError: false,
        error: null,
        flag: false,
      };
    case "FETCH_BOTS_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: {},
      };
    default:
      return { ...state };
  }
};

export const fetchAllBotsReducer = {
  allBots: fetchBotsReducer,
};
