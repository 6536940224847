import React from "react";
import "./termsconditions.scss";

const TermsConditions = () => {
  return (
    <>
      <div className="terms-container">
        <h1 className="heading">Terms of Service</h1>
        <div className="content-container">
          <p className="description">
            These Terms and Conditions apply to the website located at
            www.perpetualblock.io, (hereinafter referred to as the “website”)
            and all associated sites linked to the Website (collectively, the
            “websites”). The website is the property of Perpetualblock
            (“hereinafter referred to as "Perpetualblock").
          </p>
          <p className="content-header">1. Terms</p>
          <p className="description">
            By accessing the website at Perpetualblock, you agree to be bound by
            these terms of service, all applicable laws, and regulations and
            agree that you are responsible for compliance with any applicable
            local laws. If you do not agree with any of these terms, you are
            prohibited from using or accessing this website. The materials
            contained in this website are protected by applicable copyright and
            trademark law.
          </p>
          <p className="content-header">2. Use License</p>
          <p className="description">
            Permission is granted to temporarily download one copy of the
            materials (information or software) on this website for personal,
            non-commercial, transitory viewing only. This is the grant of a
            license, not a transfer of title, and under this license, you may
            not:
          </p>
          <ol>
            <li>Modify or copy the materials;</li>
            <li>
              Use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              Attempt to decompile or reverse engineer any software contained on
              this website;
            </li>
            <li>
              Remove any copyright or other proprietary notations from the
              materials; or transfer the materials to another person or “mirror”
              the materials on any other server.
            </li>
          </ol>
          <p className="description">
            This license shall automatically terminate if you violate any of the
            above-mentioned restrictions and/or maybe terminated by
            Perpetualblock at any time. Upon termination of this license, you
            must destroy any downloaded materials in your possession whether in
            electronic or printed format.
          </p>
          <p className="content-header">3. Disclaimer</p>
          <p className="description">
            The materials on this website are provided on an ‘as is’ basis.
            Perpetualblock makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </p>

          <p className="description">
            Further, Perpetualblock does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on this website or otherwise relating to such
            materials or on any websites linked to this website.
          </p>
          <p className="content-header">4. Limitations</p>
          <p className="description">
            In no event shall Perpetualblock or its affiliates and suppliers be
            liable for any damages (including, without limitation, damages for
            loss of data or profit, or due to business interruption) arising out
            of the use or inability to use the materials on this website, even
            if Perpetualblock or its authorized representative has been notified
            orally or in writing of the possibility of such damage. Since some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>
          <p className="content-header">5. Accuracy of materials</p>
          <p className="description">
            The materials appearing on this website may include technical,
            typographical, or photographic errors. Perpetualblock does not
            warrant that any of the materials on this website are accurate,
            complete or current. Perpetualblock may make changes to the
            materials contained on this website at any time without notice.
            However, Perpetualblock does not make any commitment to update the
            materials.
          </p>
          <p className="content-header">6. Links</p>
          <p className="description">
            Perpetualblock has not reviewed all of the websites linked to this
            website and is not responsible for the contents of any linked
            websites. The inclusion of any link does not imply endorsement by
            Perpetualblock of that website. The use of any such linked websites
            is at the user’s own risk.
          </p>
          <p className="content-header">7. Modifications</p>
          <p className="description">
            We may change the terms if we notify you of the changes and you do
            not object to those changes within a reasonable period of time set
            by us.
          </p>
          <p className="content-header">8. Governing Law</p>
          <p className="description">
            The law of the Federal Republic of Germany applies between the
            provider and the user. The legal regulations for the restriction of
            the right choice and for the applicability of compelling regulations
            in particular of the state, in which the user has his usual stay as
            a consumer, remain unaffected. If the user is a merchant, a legal
            entity under public law or a special fund under public law, the
            place of jurisdiction for all disputes is the registered office of
            the provider.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
