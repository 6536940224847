const defaultState = {
  loading: false,
  isError: false,
  error: {},
  flag: false,
  data: [],
};

const uploadDocsDataReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "UPLOAD_DOCS_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
      };
    case "UPLOAD_DOCS_SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        flag: true,
        data: action.payload,
      };
    case "UPLOAD_DOCS_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "UPLOAD_DOCS_RESET":
      return {
        ...defaultState,
      };
    default:
      return { ...state };
  }
};

export const getuploadDocsDataReducer = {
  uploadDocsData: uploadDocsDataReducer,
};
