const deleteBotSuccess = (payload) => ({
  type: "DELETE_BOT_SUCCESS",
  payload,
});

const deleteBotFailure = (error) => ({
  type: "DELETE_BOT_FAILURE",
  error,
});

const resetdeleteBotState = () => ({ type: "DELETE_BOT_RESET" });

const deleteBotCall = () => ({
  type: "DELETE_BOT_API_CALL",
});

export {
  deleteBotSuccess,
  deleteBotFailure,
  resetdeleteBotState,
  deleteBotCall,
};
