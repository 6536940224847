import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TextField, Button, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MuiAlert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./index.scss";
import pbResponseAILogo from "../../assets/pbResponseAI.png";

const Published = () => {
  const [text, setText] = useState("");

  const [copyState, setcopyState] = useState(false);
  const a = `<iframe
style={{
  position: "relative",
  height: "100%",
  width: "100%",
  bottom: "0%",
  paddingTop: "5px",
}}
title="chatbot"
src="https://preview.flutterflow.app/p-b-cars-info-ti8n96/gIt6Yq3VSSVxOTyRzIOX"
className="chatbot"
frameBorder="0"
></iframe>`;
  return (
    <div className="container">
      <img
        src={pbResponseAILogo}
        alt="pb-responseai"
        className="pbResponseAILogo"
      />

      <CopyToClipboard
        text={text}
        onCopy={() => {
          setcopyState(true);
        }}
      >
        <div className="copy-area">
          <div className="textarea" onClick={() => setText(a)}>
            <Tooltip title="Click to Copy">
              {/* <IconButton className="copy-btn"> */}
              <ContentCopyIcon className="icon"> </ContentCopyIcon>
              {/* </IconButton> */}
            </Tooltip>

            {a}
          </div>
        </div>
      </CopyToClipboard>

      <Snackbar
        open={copyState}
        autoHideDuration={3000}
        onClose={() => setcopyState(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MuiAlert
          // function called by clicking the close icon
          onClose={() => setcopyState(false)}
          // color of snack bar
          severity="success"
          sx={{ width: "100%" }}
        >
          Copied Text
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Published;
