const sendMsgSuccess = (payload) => ({
  type: "SEND_MSG_SUCCESS",
  payload,
});

const sendMsgFailure = (error) => ({
  type: "SEND_MSG_FAILURE",
  error,
});

const resetSendMsgFlag = () => ({ type: "SEND_MSG_RESET" });

const sendMsgApiCall = () => ({
  type: "SEND_MSG_API_CALL",
});

export { sendMsgSuccess, sendMsgFailure, resetSendMsgFlag, sendMsgApiCall };
