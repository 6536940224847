import * as React from "react";

import Webpages from "./Webpages";
import Documents from "./Documents";
import "./index.scss";

export default function LabTabs({ tabValue, setTabValue }) {
  return (
    <>
      <div className="tabContainer1">
        {tabValue === "0" && <Webpages />}
        {tabValue === "1" && <Documents />}
      </div>
    </>
  );
}
