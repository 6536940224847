// import React, { useEffect, useState } from "react";

// function PdfViewer({ files }) {
//   console.log(" file", files);
//   const [file, setFile] = useState(null);
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//   const [pdfDataUri, setPdfDataUri] = useState("");

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   // useEffect(() => {
//   //   const selectedFile = files[0];
//   //   console.log("selected file", selectedFile);
//   //   setFile(selectedFile);
//   //   setPageNumber(1);

//   //   if (selectedFile) {
//   //     const reader = new FileReader();
//   //     reader.onloadend = () => {
//   //       const dataUri = reader.result;
//   //       setPdfDataUri(dataUri);
//   //     };
//   //     reader.readAsDataURL(selectedFile);
//   //   }
//   // }, []);
//   useEffect(() => {
//     const selectedFile = files[0];
//     console.log("selected file", selectedFile);
//     setFile(selectedFile);
//     setPageNumber(1);

//     if (selectedFile) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const dataUri = reader.result;
//         setPdfDataUri(dataUri);
//       };
//       reader.readAsDataURL(selectedFile);
//     }
//   }, [files]);

//   return (
//     <>
//       {file && (
//         <>
//           {/* <embed src={pdfDataUri} width="100%" height="95%" /> */}
//           <iframe
//             src={pdfDataUri}
//             title="pdf-viewer"
//             width="100%"
//             height="95%"
//             style={{
//               border: "none",
//               overflow: "hidden",
//             }}
//           ></iframe>

//           {/* <iframe
//             src={`${pdfDataUri}#toolbar=0`}
//             title="n"
//             width="100%"
//             height="95%"
//             // frameBorder="0"
//             style={{
//               border: "none",
//               overflow: "hidden", // Hide the scrollbar
//             }}
//           ></iframe> */}
//           {/* <p>
//             Page {pageNumber} of {numPages}
//           </p> */}
//         </>
//       )}
//     </>
//   );
// }

// export default PdfViewer;

import React, { useEffect, useState } from "react";

function PdfViewer({ files }) {
  const [file, setFile] = useState(null);

  useEffect(() => {
    const selectedFile = files[0];
    setFile(selectedFile);
  }, [files]);

  return (
    <div style={{ width: "100%", height: "95%" }}>
      {file && (
        <object
          data={URL.createObjectURL(file)}
          type="application/pdf"
          width="100%"
          height="100%"
          hideToolbar
        >
          <p>
            Your browser does not support embedded PDF files. You can download
            the PDF{" "}
            <a
              href={URL.createObjectURL(file)}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </object>
      )}
    </div>
  );
}

export default PdfViewer;
