import React, { useState, useEffect, useCallback } from "react";
import "./voiceChat.scss";

import { Col, Input, Row, Spin } from "antd";
import ReactTypingEffect from "react-typing-effect";
import { useSpeechSynthesis } from "react-speech-kit";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ScrollableFeed from "react-scrollable-feed";

import sendIcon from "../../assets/input-send-btn.svg";
import voiceIcon from "../../assets/voice-icon.svg";
import speakWave from "../../assets/speakWave.gif";

import { useDispatch, useSelector } from "react-redux";
import { chatApiCall } from "../../redux/apiCall/chatApiCall";
import { getData } from "../../utils/storageService";
import { resetSendMsgFlag } from "../../redux/action/chatAction";

const VoiceChat = ({ questionArray, getVoiceChatData }) => {
  const chatData = useSelector((state) => state.sendMsg);
  const dispatch = useDispatch();
  // const { speak } = useSpeechSynthesis();
  const { pause } = useSpeechSynthesis();

  const [text, setText] = useState();

  const [typing, setTyping] = useState(false);
  const [updatedText, setUpdatedText] = useState([]);
  const [updatedResp, setUpdatedResp] = useState([]);

  const handleSubmit = async () => {
    if (text !== "") {
      setTyping(true);
      text !== "" && setUpdatedText({ id: 0, value: text });

      dispatch(
        chatApiCall({
          chatMsg: text,
          botID: getData("botID"),
        })
      );
    }
  };

  useEffect(() => {
    if (chatData.isError) {
      setUpdatedText({
        id: 1,
        value:
          "Sorry, the server is currently under maintenance. Please try again later.",
      });
    } else {
      if (chatData.data.output_text) {
        setUpdatedText({
          id: 1,
          value: chatData.data.output_text,
        });
        setTyping(false);
        setText("");
        dispatch(resetSendMsgFlag());
      }
    }
  }, [chatData]);

  const [typingFinished, setTypingFinished] = useState(false);

  const handleTypingFinish = () => {
    setTypingFinished(true);
  };

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    setUpdatedResp([...updatedResp, updatedText]);
  }, [updatedText]);

  useEffect(() => {
    setText(transcript);
  }, [transcript]);

  // useEffect(() => {
  //   if (updatedResp !== null) {
  //     updatedResp?.filter((ele, idx) => {
  //       if (idx === updatedResp.length - 1 && ele.id === 1) {
  //         setTimeout(() => {
  //           speak({
  //             text: ele?.value,
  //             // voice: "Google UK English Male",
  //             rate: 0.8,
  //             pitch: 1,
  //           });
  //         }, 1000);
  //       }
  //       return ele?.id === 1;
  //     });
  //   }
  // }, [updatedResp]);

  const actionHandler = {
    onSearch: (searchInfo, searchUserJourney) => {
      searchUserJourney.setSuccess();
      return searchUserJourney.AppStates.SEARCH_RESULTS;
    },

    onManageOrder: (orderInfo, orderManagementUserJourney) => {
      orderManagementUserJourney.setViewSuccess();
      return orderManagementUserJourney.AppStates.VIEW_ORDER;
    },
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const show = async () => {};

  const handleChange = (event) => {
    setText(event?.target?.value);
  };
  const handleVoiceSpeech = () => {
    SpeechRecognition.startListening();
  };

  return (
    <div className="voice-chat">
      <Col className="chat-box">
        <div className="quest-ans">
          <ScrollableFeed className="feed">
            {updatedResp !== null &&
              updatedResp?.map((ele, idx) =>
                ele?.id === 1 ? (
                  <>
                    <div className="chat-answ">
                      <div className="answer">
                        {" "}
                        {idx !== updatedResp - 1 ? (
                          <div>
                            <ReactTypingEffect
                              speed={40}
                              eraseSpeed={0}
                              eraseDelay={10000000000000}
                              text={ele?.value}
                              cursor={typingFinished ? "" : "|"}
                              onComplete={handleTypingFinish}
                            />
                          </div>
                        ) : (
                          ele.value
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {ele?.value !== undefined && (
                      <div className="chat-quest">
                        <div className="question">{ele?.value}</div>
                      </div>
                    )}
                  </>
                )
              )}
          </ScrollableFeed>
        </div>
        <div className="search-container">
          <div className="search-box">
            <Input
              className="input-box"
              placeholder="Type your query here "
              disabled={typing}
              onPressEnter={() => {
                handleSubmit();
              }}
              onChange={handleChange}
              value={text}
            ></Input>

            {!chatData?.loading ? (
              <img
                style={{ cursor: "pointer" }}
                src={sendIcon}
                alt=""
                onClick={() => {
                  handleSubmit();
                }}
              />
            ) : (
              <img
                style={{ width: "6.5%" }}
                src={require("../../assets/loader.gif")}
                alt=""
                onClick={() => {
                  handleSubmit();
                }}
              />
            )}
          </div>
          {listening ? (
            <img
              src={speakWave}
              alt="speaking"
              style={{ cursor: "pointer" }}
              className="listeningIcon"
            />
          ) : (
            <img
              src={voiceIcon}
              alt=""
              className="speechIcon"
              onClick={handleVoiceSpeech}
            />
          )}
        </div>
      </Col>
    </div>
  );
};

export default VoiceChat;
