import { toast } from "react-toastify";
import apiClient from "../../utils/apiClient";
import { getAuthHeader } from "../../utils/sessionManagement";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";

import {
  fetchBotsFailure,
  fetchBotsApiCall,
  fetchBotsSuccess,
} from "../action/fetchAllBots";
import { apiConfig } from "../apiConfig/fetchAllBotsApiConfig";
export const getAllBots = (payload) => (dispatch) => {
  let apiPayload = { ...apiConfig.GET_ALL_BOT };
  apiPayload.data = { email: getAuthHeader("Email") };
  dispatch(apiBegin());
  dispatch(fetchBotsApiCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(fetchBotsSuccess(res?.data));
    })
    .catch((err) => {
      dispatch(fetchBotsFailure(err));
      dispatch(apiFailure(err));
      toast.error("something went wrong please try again");
    });
};
