import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";

const YouTubeUpload = () => {
  const [open, setOpen] = useState(false);
  const [youtubeLink, setYouTubeLink] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const modalContent = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,

        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 2,
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <TextField
          hiddenLabel
          id="filled-hidden-label-normal"
          value={youtubeLink}
          className="website-textfield"
          onChange={(e) => {
            setYouTubeLink(e.target.value);
          }}
        />
      </div>
    </Box>
  );
  return (
    <div className="youtube-upload">
      <Typography className="text">
        Create new chat to ask about your <br></br>Youtube video.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className="new-video"
        onClick={handleOpen}
      >
        <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon> &nbsp; New
        Video
      </Button>
      <Button variant="outlined" color="primary" className="new-video">
        <DeleteForeverOutlinedIcon></DeleteForeverOutlinedIcon> &nbsp; Delete
        All Chats
      </Button>
      <Modal open={open} onClose={handleClose}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default YouTubeUpload;
