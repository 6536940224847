import React, { useEffect, useState } from "react";
import PieChart from "react-apexcharts";
import Chart from "react-apexcharts";
import BarChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./index.scss";
import { Button } from "@mui/material";
import cloud from "../../assets/wordcloud.png";
import arrow from "../../assets/Vector.svg";
import { useNavigate } from "react-router-dom";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import {
  InputAdornment,
  TextField,
  Typography,
  Card,
  Checkbox,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const barChartData = {
  series: [
    {
      name: "% Understanding",
      data: [43, 58, 77, 13, 80],
    },
  ],
  options: {
    colors: ["#008FFB", "#00E396", "#FF4560", "#FFBB00", "#775DD0"],
    xaxis: {
      categories: ["biopsy", "biomarker", "mutations", "cfdna", "tissue"],
      labels: {
        style: {
          fontSize: "16px",
          fontFamily: "'Poppins', sans-serif",
        },
      },
    },
    legend: { show: false },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },

    yaxis: {
      title: {
        text: "% Understanding",
        style: {
          fontSize: "18px",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500,
        },
      },
      labels: {
        style: {
          fontSize: "16px",
          fontFamily: "'Poppins', sans-serif",
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: "18px",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "bold",
        colors: ["#ffffff"],
      },
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "'Poppins', sans-serif",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  },
};

const fileName = "TrainingResult.pdf";
const filePath = process.env.PUBLIC_URL + "/" + fileName;

// const employeeData = [
//   {
//     id: 43,
//     name: "Bharat",
//     docUrl: filePath,
//   },
//   { id: 54, name: "Shubham", docUrl: filePath },
// ];

const Assessment = () => {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [employeeData, setEmployeeData] = useState();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#001240",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleDownload = (docUrl) => {
    axios
      .get(`${docUrl}`, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      })
      .then(function (response) {
        console.log(response);
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Training Result.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again !!!");
      });
    // console.log({ docUrl });
    // const link = document.createElement("a");
    // link.href = docUrl;

    // link.download = "TrainingResult.pdf";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/training_results`)
      .then(function (response) {
        console.log(response);
        setEmployeeData(response?.data?.training_results);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again !!!");
      });
  }, []);
  // const fail = employeeData?.filter((e) => parseInt(e.score.split("/")[0])
  //   < 5).length;
  // const pass = employeeData.length - fail;
  // const x = 10 - pass - fail;
  // console.log({ fail });
  const pieChartData = {
    series: [82, 24, 36],
    options: {
      colors: ["#14B8A6", "#3B82F6", "#F59E0B"],
      labels: ["Pass", "Fail", "Yet to attempt"],
      legend: {
        show: true,
        floating: false,
        fontSize: "18px",
        fontFamily: "'Poppins', sans-serif",
        position: "right",
        offsetX: 350,
        offsetY: 25,
        labels: {
          useSeriesColors: true,
        },
        formatter: function (seriesName, opts) {
          console.log("opts", opts);
          const _ = require("lodash");
          const series = opts?.w?.globals?.series;
          return (
            seriesName +
            ":  " +
            Math.trunc((series[opts?.seriesIndex] / _.sum(series)) * 100) +
            "%"
          );
        },
        markers: {
          width: 0,
        },

        onItemHover: {
          highlightDataSeries: true,
        },
      },
      plotOptions: {
        radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 270,
          offsetX: 100,
          offsetY: 0,
          hollow: {
            size: "40%",
            background: "transparent",
          },
          track: {
            show: false,
            // startAngle: undefined,
            // endAngle: undefined,
            // background: "#f2f2f2",
            // strokeWidth: "97%",
            // opacity: 1,
            // margin: 5,
            // dropShadow: {
            //   enabled: false,
            //   top: 0,
            //   left: 0,
            //   blur: 3,
            //   opacity: 0.5,
            // },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "40px",
              fontFamily: "'Poppins', sans-serif",
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: "Total",
              fontSize: "24px",
              fontFamily: "'Poppins', sans-serif",
              color: "#373d3f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };
  return (
    <div className="assessment">
      <img
        alt=""
        src={arrow}
        className="arrow-btn"
        onClick={() => navigate(-1)}
      ></img>
      <div className="title">Learning Module Dashboard</div>
      <div className="prompt">
        {/* Training Modules : Aggarwal JAMA Oncol 2018 G360 for NSCLC, NILE STUDY */}
      </div>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between ",
          marginTop: "3%",
        }}
      >
        <div style={{ width: "50%" }}>
          <h3 style={{ padding: "1%" }}>TEAM PERFORMANCE :</h3>
          <br />
          {/* <PieChart
            options={pieChartData.options}
            series={pieChartData.series}
            type="pie"
            height={250}
          /> */}
          <Chart
            options={pieChartData.options}
            series={pieChartData.series}
            type="radialBar"
            width={750}
            height={460}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
        <div style={{ width: "40%" }}>
          <h3 style={{ padding: "1%" }}>TRAINING SUGGESTIONS :</h3>
          <BarChart
            options={barChartData.options}
            series={barChartData.series}
            type="bar"
            height={450}
            // style={{ display: "flex", alignItems: "center", height: "10%" }}
          />
        </div>
      </div>
      <TableContainer component={Paper} className="result">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 style={{ padding: "1%" }}>ASSESSMENT</h3>
          <TextField
            // className="research-searchbar"
            placeholder="abc@gmail.com"
            variant="outlined"
            style={{ padding: "1%", height: "80px" }}
            // multiline={true}
            value={text}
            onKeyDown={(e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
              } /* else if (e.shiftKey && e.key === "Enter") {
                setMultiline(true);
              } */
            }}
            onChange={(e) => setText(e?.target?.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharpIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained">SEARCH</Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell className="table-cell-style">
                Employee ID
              </StyledTableCell>
              <StyledTableCell className="table-cell-style">
                Employee Name
              </StyledTableCell>
              <StyledTableCell className="table-cell-style">
                Score
              </StyledTableCell>

              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeData?.map((employee) => (
              <StyledTableRow key={employee.employeeID}>
                <TableCell className="table-cell-style">
                  {employee.employeeID}
                </TableCell>
                <TableCell className="table-cell-style">
                  {employee.employeeName}
                </TableCell>
                <TableCell className="table-cell-style">
                  {employee.score}
                </TableCell>

                <TableCell>
                  <Button
                    onClick={() => handleDownload(employee.result_url)}
                    variant="contained"
                    color="primary"
                  >
                    Download
                  </Button>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div></div> */}
      {/* <Paper style={{ height: "fit-content" }}> */}
      <h3 style={{ marginTop: "5%" }}>TOPICS OF INTERESTS :-</h3>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={cloud}
          alt=""
          style={{
            backgroundColor: "#ffffff",
            height: "600px",
          }}
        />
      </div>
      {/* </Paper> */}
    </div>
  );
};

export default Assessment;
