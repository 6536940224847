import apiClient, { setHeader } from "../../utils/apiClient";
import { addSession } from "../../utils/sessionManagement";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";
import {
  createBotCall,
  createBotFailure,
  createBotSuccess,
} from "../action/createBotActions";
import { apiConfig } from "../apiConfig/createBotApiConfig";

export const startCreateBot = (payload) => (dispatch) => {
  const apiPayload = { ...apiConfig.CREATE };
  apiPayload.data = payload;
  dispatch(apiBegin());
  dispatch(createBotCall());
  apiClient(apiPayload)
    .then((res) => {
      addSession(res?.data);
      dispatch(createBotSuccess(res));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(createBotFailure(err));
      dispatch(apiFailure(err));
    });
};
