import React, { useState } from "react";
import QuestionComponent from "./Question";
import { Button } from "@mui/material";
import img from "../../assets/homebackground.png";
import axios from "axios";

// import "./index.scss";

const QuizComponent = ({ quiz }) => {
  console.log("quiz", quiz);
  const questions = quiz?.questions;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleNextQuestion = (selectedChoice) => {
    setUserAnswers([
      ...userAnswers,
      { questionNumber: currentQuestionIndex + 1, selectedChoice },
    ]);

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const docContent = userAnswers
    .map((answer) => {
      const questionIndex = answer.questionNumber - 1;
      const question = questions[questionIndex];
      const isCorrect = question.correct_choice === answer.selectedChoice;

      const formattedQuestion = `Question ${answer.questionNumber}: ${question.question}`;
      const formattedChoices = Object.entries(question.choices)
        .map(([key, choice]) => ` ${choice}`) /*  ${key}:*/
        .join("\n");
      return `${formattedQuestion}\n\n${formattedChoices}\n\nCorrect Option - ${
        question.correct_choice
      }\nYour Answer - ${answer.selectedChoice}\n${
        isCorrect ? "Correct" : "Incorrect"
      }`;
    })
    .join("\n\n\n");
  const handleDownload = () => {
    // console.log({ docContent });
    // const blob = new Blob([docContent], { type: "text/plain" });

    // const downloadLink = document.createElement("a");
    // downloadLink.href = URL.createObjectURL(blob);
    // downloadLink.download = "quiz_results.doc";
    // downloadLink.click();
  const x=  userAnswers.filter((ans) => {
      const questionIndex = ans.questionNumber - 1;
      return (
        questions[questionIndex].correct_choice ===
        ans.selectedChoice
      );
    }).length;
    console.log({x});
    const requestBody = {
      training_result: docContent,
      employee_id:"43",
      employee_name:"Bharat",
      training_score:`${x}/10`
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/training_submit`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      )
      .then(function (response) {
        console.log(response.data);
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Training Result.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const allQuestionsAnswered =
    currentQuestionIndex === questions.length &&
    userAnswers.length === questions.length;

  // const printDocContent = () => {
  //   docContent?.map((key, index) => {
  //     console.log(index, key);
  //   });
  // };

  return (
    <div
      style={{
        backgroundImage: `url(${img})`,

        // backgroundColor: "#f8f8f8",
        // borderRadius: "10px",
        // padding: "20px",
        display: "flex",
        flexDirection: "column",
        maxHeightheight: "350vh",
        scroll: "auto",
        width: "98vw",
        // alignItems: "center",
      }}
    >
      {allQuestionsAnswered ? (
        <div
        // style={{
        //   backgroundImage: `url(${img})`,

        //   // backgroundColor: "#f8f8f8",
        //   // borderRadius: "10px",
        //   // padding: "20px",
        //   display: "flex",
        //   flexDirection: "column",
        //   maxHeightheight: "350vh",
        //   scroll: "auto",
        //   // alignItems: "center",
        // }}
        >
          <div
            style={{
              backgroundColor: "#D1DDFC",
              border: " 1px solid #ccc",
              bordeRadius: " 8px",
              gap: "8%",
              padding: "20px",
              cursor: "pointer",
              width: "265px",

              boxShadow: " 10px 10px 5px -3px rgba(0, 0, 0, 0.75)",
            }}
          >
            <h3>Employee Name : Bharat</h3>

            <h3>Employee Id :43</h3>
          </div>
          <h2 style={{ textAlign: "center", color: "#001A5C" }}>
            Quiz completed!
            <br /> You have answered &nbsp;
            <h3 style={{ display: "inline-block", color: "#0074FC" }}>
              <span
                style={{
                  color:
                    userAnswers.filter((ans) => {
                      const questionIndex = ans.questionNumber - 1;
                      return (
                        questions[questionIndex].correct_choice ===
                        ans.selectedChoice
                      );
                    }).length < 5
                      ? "red"
                      : "green",
                }}
              >
                {
                  userAnswers.filter((ans) => {
                    const questionIndex = ans.questionNumber - 1;
                    return (
                      questions[questionIndex].correct_choice ===
                      ans.selectedChoice
                    );
                  }).length
                }
              </span>{" "}
              / 10
            </h3>{" "}
            questions correctly
          </h2>
          {userAnswers.map((answer) => {
            const questionIndex = answer.questionNumber - 1;
            const question = questions[questionIndex];
            const isCorrect = question.correct_choice === answer.selectedChoice;

            return (
              <div
                key={questionIndex}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  marginBottom: "20px",
                  padding: "20px",
                  margin: "2%",
                  boxShadow: "10px 10px 5px -3px rgba(0, 0, 0, 0.75)",
                  // backdropFilter: "blur(2px)",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
              >
                <h4>
                  Question {answer.questionNumber}: {question.question}
                </h4>{" "}
                <br />
                <div>
                  {Object.entries(question.choices).map(([key, choice]) => (
                    <div
                      key={key}
                      style={{
                        // color: isCorrect ? "green" : "red",
                        marginBottom: "8px",
                      }}
                    >
                      {choice}
                    </div>
                  ))}
                </div>{" "}
                <br />
                <p>
                  Correct Option: {question.correct_choice}, <br /> <br />
                  Your Answer: {answer.selectedChoice}
                </p>
                <div style={{ color: isCorrect ? "green" : "red" }}>
                  {isCorrect ? "Correct" : "Incorrect"}
                </div>
              </div>
            );
          })}

          {/* <pre
          // style={{ }}
          className="docContent"
        >
          {docContent}
        </pre> */}
          {/* {printDocContent()} */}
          {console.log("doc", docContent)}
          <Button
            variant="contained"
            style={{
              width: "25%",
              padding: "1%",
              fontSize: "16px",
              marginLeft: "38%",
              marginBottom: "2%",
            }}
            onClick={handleDownload}
          >
            Download Results
          </Button>
        </div>
      ) : (
        <QuestionComponent
          question={questions[currentQuestionIndex]}
          onNextQuestion={handleNextQuestion}
        />
      )}
    </div>
  );
};

export default QuizComponent;
