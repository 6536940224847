import { deleteData, getData, setData } from "./storageService";

export const clearSession = () => {
  deleteData();
};

export const addSession = (session) => {
  setData("isLoggedIn", true);
  if (session.email) {
    setData(`Email`, session?.email);
  }
  session?.botID && setData(`botID`, session?.botID);

  // setData(`Cookie`, "session?.Cookie");
  // setData(`Token`, "session?.Token");
};

export const checkIfLogin = () => getData("isLoggedIn") || false;

export const getAuthHeader = (text) => getData(text);
