import React from "react";
import DocumentUploader from "./Upload";

const Documents = ({ setActiveStep }) => {
  return (
    <div className="document">
      <h1 style={{ paddingTop: "3%" }}>AI Summarization with Document</h1>
      <div style={{ textAlign: "center" }}>
        Our High defined system understand content quickly<br></br> and will
        Summarize your query.
      </div>
      <br />
      <small style={{ color: "#153359" }}>
        Supportable file :Google Sheets,Docs,PDFs
      </small>
      <br />

      <DocumentUploader setActiveStep={setActiveStep} />
    </div>
  );
};

export default Documents;
