import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Button,
  CardContent,
  Card,
} from "@mui/material";
import "./index.scss";
import axios from "axios";
import backArrow from "../../assets/backErrow.svg";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useNavigate } from "react-router-dom";
const Research = () => {
  let conversation = [];
  const navigate = useNavigate();

  const token = "ac2a13b0f15c34877c2b67969906e082";
  const [text, setText] = useState();
  const [researchResponse, setReasearchResponse] = useState([]);
  const [showLoader, setShowLoader] = useState();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      // Access-Control-Allow-Origin: *
    },
  };

  const handleSearch = () => {
    conversation = [...conversation, { role: "user", content: text }];
    const bodyParameters = { messages: conversation };
    setShowLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BIO_BOT_BACKEND_URL}/search`,
        bodyParameters,
        config
      )
      .then(function (response) {
        setReasearchResponse(response?.data?.choices);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const renderCards = () => {
    const inputText = researchResponse[0]?.message?.content;
    const lines = inputText?.split("\n\n");
    // const newArray = lines?.map((element) => element.replace(/'/g, " "));

    lines?.pop();

    return lines?.map((e, idx) => {
      const a = e.split("\n");
      return (
        <div className="research-card" key={idx}>
          <Card>
            <CardContent className="research-card-content">
              {/* <pre
                  style={{
                    color: "rgba(1, 26, 89, 1)",
                  }}
                > */}
              <p
                style={{
                  color: "rgba(1, 26, 89, 1)",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {a[0]}
              </p>
              <br />
              <a
                href={` ${a[1]}`}
                rel="noreferrer"
                target="_blank"
              >{` ${a[1]}`}</a>
              {/* </pre> */}
            </CardContent>
          </Card>
        </div>
      );
    });
  };
  return (
    <div className="research">
      <div style={{ display: "flex" }}>
        <img
          src={backArrow}
          alt=""
          style={{
            cursor: "pointer",
            marginBottom: "10px",
            paddingRight: "3%",
          }}
          onClick={() => navigate(-1)}
        />
        <TextField
          className="research-searchbar"
          placeholder="Search For Your Query"
          variant="outlined"
          value={text}
          onChange={(e) => setText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchSharpIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <InputAdornment position="end">
                  <Button variant="contained" onClick={handleSearch}>
                    SEARCH
                  </Button>
                </InputAdornment>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {showLoader ? (
        <div className="cssload-container">
          <div className="cssload-whirlpool"></div>
        </div>
      ) : (
        <div>{renderCards()}</div>
      )}
    </div>
  );
};

export default Research;
