const defaultState = {
  loading: false,
  isError: false,
  error: {},
  flag: false,
  data: [],
};

const createBotDataReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "CREATE_BOT_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
      };
    case "CREATE_BOT_SUCCESS":
      return {
        ...state,
        loading: false,
        isError: false,
        flag: true,
        data: action.payload,
      };
    case "CREATE_BOT_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "CREATE_BOT_RESET":
      return {
        ...defaultState,
      };
    default:
      return { ...state };
  }
};

export const getcreateBotDataReducer = {
  createBotData: createBotDataReducer,
};
