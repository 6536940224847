import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

import earthimg from "../../assets/pajamas_earth.svg";
import web from "../../assets/webpage.svg";
import { submitUploadLinkCall } from "../../redux/apiCall/webpageApiCall";
import "./index.scss";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { getAuthHeader } from "../../utils/sessionManagement";
import { getData } from "../../utils/storageService";
const Webpages = () => {
  const {
    data: uploadLinkData,
    loading: linkLoading,
    status: uploadLinkStatus,
  } = useSelector((state) => state.webpageUploadLink);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search === "") {
      toast.warn("Please enter your link");
      return;
    }
    dispatch(
      submitUploadLinkCall({
        webURL: search,
        email: getAuthHeader("Email"),
        botID: getData("botID"),
      })
    );
    sessionStorage.setItem("websiteurl", search);
  };

  return (
    <div className="webpage">
      <h1> Create Knowledge Base</h1>
      <h4 className="importlink">Import Links</h4>

      <div className="searchContent">
        <TextField
          className={"searchField"}
          type="search"
          id="search"
          label={
            <div style={{ overflow: "visible" }} className="searchbar">
              <img src={earthimg} alt=""></img>{" "}
              <span>https://www.example.com</span>
            </div>
          }
          sx={{ width: 600 }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />

        <LoadingButton
          loading={linkLoading}
          variant="contained"
          className="scan-btn"
          startIcon={<img src={web} alt=""></img>}
          onClick={handleSubmit}
        >
          Scan WebPage
        </LoadingButton>
      </div>
    </div>
  );
};

export default Webpages;
/**  <iframe
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    bottom: "0%",
                    paddingTop: "5px",
                  }}
                  title="chatbot"
                  src="https://preview.flutterflow.app/p-b-cars-info-ti8n96/gIt6Yq3VSSVxOTyRzIOX"
                  className="chatbot"
                  frameBorder="0"
                ></iframe> */
