import { Button } from "@mui/material";
import pbbotdesc from "../../assets/pbbotdesc.png";
import youtube from "../../assets/youtube.svg";
import image from "../../assets/image.svg";
import pdf from "../../assets/pdf.svg";

import "./index.scss";
import { useState } from "react";
import SignUpDialog from "../Signup";
import { checkIfLogin } from "../../utils/sessionManagement";
import { useNavigate } from "react-router-dom";

const Home = ({ open, setOpen, signInFlag }) => {
  const navigate = useNavigate();
  const handleClick = (text) => {
    if (!checkIfLogin()) {
      setOpen(true);
      return;
    }
    switch (text) {
      case "BuildChatbot":
        navigate("/allBots");
        break;

      default:
        break;
    }
  };
  return (
    <div className="home">
      {/* <SignUpDialog signInFlag={signInFlag} open={open} setOpen={setOpen} /> */}
      <div className="center-content">
        <img src={pbbotdesc} alt="pb-response-bot" className="pbbotdesc" />
        <p className="text">
          PBResponse is an Al-powered chatbot platform that offers personalized
          and relevant responses to customer inquiries. It uses OpenAl's GPT
          technology to provide prompt and authentic responses that mimic
          human-like communication. PBResponse helps deepen customer
          relationships, drive sales growth, and offers expertise on your
          product or service. It also offers real-time lead classification and
          proactive engagement features. Overall, PBResponse aims to provide a
          more natural and effective messaging experience for businesses.{" "}
        </p>
        <Button
          variant="contained"
          className="explore-btn"
          onClick={() => handleClick("BuildChatbot")}
        >
          Build a Chatbot
        </Button>

        <div className="otherBtnBox">
          <Button variant="contained" className="btn-2" onClick={handleClick}>
            <img src={youtube} alt="" />
            <span className="btn-2-text"> Ask about your YouTube video</span>
          </Button>{" "}
          <Button variant="contained" className="btn-2" onClick={handleClick}>
            <img src={image} alt="" />
            <span>Create your own images </span>
          </Button>{" "}
          <Button variant="contained" className="btn-2" onClick={handleClick}>
            <img src={pdf} alt="" />
            <span>Document or PDF </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Home;
