import React, { useState, useEffect } from "react";
import "./bioBot.scss";
import { Button, Col, Row } from "antd";
import { useSpeechSynthesis } from "react-speech-kit";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ScrollableFeed from "react-scrollable-feed";
import sendIcon from "../../assets/input-send-btn.svg";
import History from "./History";
import { useDispatch } from "react-redux";
import Summary from "./Summary";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import speaking from "../../assets/speak_wave.gif";
import { CircularProgress, TextField, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import HighlightModal from "./HighlightModal";
import { getData } from "../../utils/storageService";
import { CleaningServices } from "@mui/icons-material";

let conversation = [];

const BiobotChat = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const [text, setText] = useState("");
  const [lang, setLanguage] = useState("eng");
  const [updatedText, setUpdatedText] = useState("");
  const [summary, setSummary] = useState("");

  const [open, setOpen] = useState(false);
  const [pdfModal, SetPdfModal] = useState(false);

  const [typing, setTyping] = useState(false);
  const [updatedResp, setUpdatedResp] = useState([]);

  const { speak, cancel } = useSpeechSynthesis();

  const [audioStream, setAudioStream] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isPaused, setIsPaused] = useState(true);
  const [pauseIndex, setPauseIndex] = useState(-1);
  const [chatID, setChatID] = useState(urlParams.get("chatID") || "");
  const [chatHistory, setChatHistory] = useState([]);
  const [chatEsIndex, setChatEsIndex] = useState("");

  const [pdfUrlList, setPdfUrlList] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");

  const [chatLoading, setChatLoading] = useState(false);
  const [lungCancerBtnFlag, setLungCancerBtnFlag] = useState(true);
  const [showBtnIndex, setShowBtnIndex] = useState(null);

  const token = process.env.REACT_APP_BIO_BOT_TOKEN;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      // Access-Control-Allow-Origin: *
    },
  };

  const handleSubmit = async (e, endpoint) => {
    if (e) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault(); // Prevent the default line break behavior for simple Enter key press
      }

      if (e.key !== "Enter") {
        return;
      }

      if (e.key === "Enter" && e.shiftKey) {
        return;
      }
    }

    if (text === "" && endpoint !== "summerize") {
      return;
    }

    setLoading(endpoint);
    if (text !== "") {
      setUpdatedText({ role: "user", content: text });

      conversation = [...updatedResp, { role: "user", content: text }];
    } else if (endpoint === "summerize") {
      conversation = [...updatedResp];
    }

    const bodyParameters = {
      chatID: chatID,
      messages: conversation,
      emailID: getData("Email"),
      esIndex: chatEsIndex,
    };

    setTyping(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
        bodyParameters,
        config
      )
      .then(function (response) {
        setLoading(null);
        setLungCancerBtnFlag(false);

        if (urlParams.get("chatID") !== response?.data?.chatID) {
          urlParams.set("chatID", response?.data?.chatID);
          navigate(`${location.pathname}?${urlParams.toString()}`);
          // getHistoryCardsData();
        }

        setChatID(response?.data?.chatID);

        conversation = [
          ...conversation,
          {
            role: "assistant",
            content: response?.data?.choices[0]?.message?.content,
          },
        ];

        if (endpoint === "summerize") {
          setSummary(response?.data?.choices[0]?.message?.content);
          setOpen(true);
        } else {
          setUpdatedText({
            role: "assistant",
            content: response?.data?.choices[0]?.message?.content,
          });

          setPdfUrlList(response?.data?.choices[0]?.message?.docLinks);
        }
        setTyping(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(null);
        toast.error(
          "Sorry, the server is currently under maintenance. Please try again later."
        );

        setTyping(false);
      });

    setText("");
  };

  const handleSubmitLungCancer = (ans) => {
    setLoading(true);
    setLungCancerBtnFlag(true);
    setShowBtnIndex(updatedResp.length + 1);

    setUpdatedText({ role: "user", content: ans });
    conversation = [...updatedResp, { role: "user", content: ans }];

    let newConversation = [];

    conversation.map((item, i) => {
      if (item.role === "user") {
        newConversation.push(item);
        if (conversation.length > i + 1)
          newConversation.push(conversation[i + 1]);
      }
    });

    const bodyParameters = {
      chatID: chatID,
      messages: newConversation,
      emailID: getData("Email"),
      esIndex: chatEsIndex,
      lang: lang,
    };

    setTyping(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/chatLungCancer`,
        bodyParameters,
        config
      )
      .then(function (response) {
        setLoading(null);

        if (urlParams.get("chatID") !== response?.data?.chatID) {
          urlParams.set("chatID", response?.data?.chatID);
          navigate(`${location.pathname}?${urlParams.toString()}`);
          // getHistoryCardsData();
        }

        setChatID(response?.data?.chatID);
        conversation = [
          ...conversation,
          {
            role: "assistant",
            content: response?.data?.choices[0]?.message?.content,
          },
        ];

        setUpdatedText({
          role: "assistant",
          content: response?.data?.choices[0]?.message?.content,
        });
        setPdfUrlList(response?.data?.choices[0]?.message?.docLinks);

        setTyping(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(null);
        toast.error(
          "Sorry, the server is currently under maintenance. Please try again later."
        );

        setTyping(false);
      });
  };

  const openPdfModal = (pdfUrl) => {
    // const jsonString = chunk?.metadata?.source
    //   ?.replace(/'/g, '"')
    //   ?.replace(/ObjectId\(/g, "")
    //   ?.replace(/\)/g, "");
    // const parsedData = JSON.parse(jsonString);
    // const url = parsedData.url.split("/");
    // const s = url.pop();

    // setHighlightChunk(chunk);
    setPdfUrl(pdfUrl);
    SetPdfModal(true);
  };
  const closePdfModal = () => {
    SetPdfModal(false);
  };

  const getHistoryCardsData = (setHistoryLoading) => {
    setHistoryLoading(true);
    const bodyParameters = { emailID: getData("Email") };
    // setChatID(chatId);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/${"getchathistory"}`,
        bodyParameters,
        config
      )
      .then(function (response) {
        setHistoryLoading(false);
        setChatHistory(response.data.chats);
      })
      .catch(function (error) {
        console.log(error);
        setHistoryLoading(false);
      });
  };

  const { listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }
  }, [!listening]);

  useEffect(() => {
    updatedText && setUpdatedResp([...updatedResp, updatedText]);
  }, [updatedText]);

  const handlePlay = (type, idx, content) => {
    setPauseIndex(idx);

    if (type === "play") {
      speak({
        text: content,
        // voice: "Google UK English Male",
        rate: 0.9,
        pitch: 1,
      });
      setIsPaused(true);
    } else {
      setIsPaused(false);
      cancel();
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const show = async () => {};

  let mediaRecorder;
  const handleChange = (event) => {
    setText(event?.target?.value);
  };
  const handleVoiceSpeech = async () => {
    SpeechRecognition.startListening();

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);

      mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = async (event) => {
        console.log("audioFun called", event);
        if (event.data.size > 0) {
          const audioBlob = new Blob([event.data], { type: "audio/mp3" });
          let token =
            "Bearer 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";

          let headers = {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          };

          const formData = new FormData();
          formData.append("audio", audioBlob, "audio.mp3");
          let data = {
            file: audioBlob,
            model: "whisper-1",
            language: "en",
          };
          const response = await axios.post(
            "https://api.openai.com/v1/audio/transcriptions",
            // formData,
            data,
            { headers }
            // {
            //   headers: {
            //     "Authorization": `Bearer ${token}`,
            //     "Content-Type": "multipart/form-data",
            //   },
            // }
          );

          setText(response.data.text);
        }
      };

      mediaRecorder.start();
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  return (
    <>
      <div className="biobot-chat">
        <Row gutter={32}>
          {pdfModal && (
            <HighlightModal
              pdfUrl={pdfUrl}
              open={pdfModal}
              handleClose={closePdfModal}
            />
          )}
          <Col
            xl={6}
            className="history-box"
            style={
              {
                // background: "#ffffffcc",
                // opacity: "0.2",
              }
            }
          >
            <History
              setChatEsIndex={setChatEsIndex}
              setUpdatedResp={setUpdatedResp}
              getHistoryCardsData={getHistoryCardsData}
              chatHistory={chatHistory}
              setChatID={setChatID}
              setChatLoading={setChatLoading}
            />
          </Col>
          <Col className="chat-box" xl={18}>
            <div className="quest-ans">
              <ScrollableFeed className="feed">
                {chatLoading ? (
                  <center>
                    <div>
                      <CircularProgress />
                    </div>
                  </center>
                ) : (
                  <>
                    {updatedResp.length <= 0 &&
                      chatEsIndex === "Lung Cancer" &&
                      lang == "eng" && (
                        <div className="chat-quest">
                          <Button
                            className="small-btn"
                            onClick={() => setLanguage("German")}
                          >
                            Switch to German
                          </Button>
                        </div>
                      )}

                    {updatedResp.length <= 0 && (
                      <>
                        <div className="chat-answ">
                          <div className="answer">
                            {console.log({ lang })}
                            <pre className="ans-pre">
                              {lang == "eng"
                                ? `Hi, I am PowerBot, your helpful AI collaborator. I am trained in the knowledge of your interest. \n\n How can I help you today?`
                                : `Hallo Bharat, ich bin BioBot, Ihr kreativer und hilfsbereiter KI-Mitarbeiter. Ich bin in den Forschungsartikeln geschult, die Sie interessieren. \n\n Wie kann ich Ihnen heute helfen?`}
                            </pre>
                          </div>
                        </div>
                      </>
                    )}
                    {updatedResp.length <= 0 &&
                      chatEsIndex === "Lung Cancer" && (
                        <div className="chat-quest">
                          <Button
                            className="small-btn"
                            onClick={() => handleSubmitLungCancer(`GP`)}
                          >
                            {lang == "eng" ? `GP` : `Hausarzt`}
                          </Button>
                          <Tooltip
                            placement="top"
                            title={
                              lang == "eng"
                                ? `Hospitals referrals`
                                : `Krankenhausüberweisungen bei Verdacht auf Lungenkrebs`
                            }
                          >
                            <Button
                              className="small-btn"
                              onClick={() =>
                                handleSubmitLungCancer(
                                  lang == "eng"
                                    ? `Hospitals referrals for suspected lung cancer`
                                    : `Krankenhausüberweisungen bei Verdacht auf Lungenkrebs`
                                )
                              }
                            >
                              {lang == "eng"
                                ? `Hospitals referrals`
                                : `Krankenhausüberweisungen bei Verdacht auf Lungenkrebs`}
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    {updatedResp.length > 0 &&
                      updatedResp?.map((ele, idx) =>
                        ele?.role === "assistant" ? (
                          <>
                            <div className="chat-answ" key={idx}>
                              <div className="answer">
                                <pre className="ans-pre">{ele?.content}</pre>

                                <div className="play-pause-btn">
                                  {isPaused && pauseIndex === idx ? (
                                    <PauseCircleOutlineIcon
                                      onClick={() =>
                                        handlePlay("pause", idx, null)
                                      }
                                    />
                                  ) : (
                                    <PlayCircleOutlineIcon
                                      onClick={() =>
                                        handlePlay("play", idx, ele?.content)
                                      }
                                    />
                                  )}
                                </div>
                                {updatedResp.length - 1 === idx && (
                                  <div className="chunksBtn">
                                    {pdfUrlList?.map((PdfUrl, index) => {
                                      return (
                                        <>
                                          {PdfUrl && (
                                            <Button
                                              key={index}
                                              className="chunk"
                                              type="primary"
                                              onClick={() =>
                                                openPdfModal(PdfUrl)
                                              }
                                            >
                                              {index + 1}
                                            </Button>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          ele?.content !== undefined && (
                            <>
                              {!lungCancerBtnFlag &&
                                chatEsIndex === "Lung Cancer" &&
                                showBtnIndex === idx - 1 &&
                                !loading &&
                                !JSON.stringify(updatedResp).includes(
                                  lang == "eng"
                                    ? "GP manages the patient"
                                    : `Der Hausarzt verwaltet den Patienten`
                                ) &&
                                updatedResp[updatedResp.length - 1]?.content !=
                                  (lang == "eng"
                                    ? "GP manages the patient"
                                    : `Der Hausarzt verwaltet den Patienten`) && (
                                  <div className="chat-quest">
                                    {console.log("here")}
                                    <Button
                                      className="small-btn"
                                      onClick={() =>
                                        handleSubmitLungCancer(
                                          lang == "eng" ? `yes` : `ja`
                                        )
                                      }
                                    >
                                      {lang == "eng" ? `Yes` : `Ja`}
                                    </Button>
                                    <Button
                                      className="small-btn"
                                      onClick={() =>
                                        handleSubmitLungCancer(
                                          lang == "eng" ? `no` : `nein`
                                        )
                                      }
                                    >
                                      {lang == "eng" ? `No` : `NEIN`}
                                    </Button>
                                  </div>
                                )}
                              <div className="chat-quest">
                                <div className="question">
                                  <pre className="ques-pre">{ele?.content}</pre>
                                </div>
                              </div>
                            </>
                          )
                        )
                      )}

                    {updatedResp.length > 0 &&
                      chatEsIndex === "Lung Cancer" &&
                      lungCancerBtnFlag &&
                      !loading &&
                      !JSON.stringify(updatedResp).includes(
                        lang == "eng"
                          ? "GP manages the patient"
                          : `Der Hausarzt verwaltet den Patienten`
                      ) &&
                      updatedResp[updatedResp.length - 1]?.content !=
                        (lang == "eng"
                          ? "GP manages the patient"
                          : `Der Hausarzt verwaltet den Patienten`) && (
                        <div className="chat-quest">
                          {console.log(
                            updatedResp[updatedResp.length - 1],
                            updatedResp[updatedResp.length - 1]?.content ==
                              "Fast track lung cancer clinic. Diagnostic process plan / diagnostic planning meeting prior to clinic. Treatment of co-morbidity and palliation / treatment of symptoms. LCNS: Prehabilitation/social/psychological assessment. Clarity/reassurance re complex tests and next steps++Direct biopsy option TRIAGE by radiology or respiratory medicine according to local protocol. Lung cancer likely?"
                          )}
                          {updatedResp[updatedResp.length - 1]?.content ==
                          (lang == "eng"
                            ? "Fast track lung cancer clinic. Diagnostic process plan / diagnostic planning meeting prior to clinic. Treatment of co-morbidity and palliation / treatment of symptoms. LCNS: Prehabilitation/social/psychological assessment. Clarity/reassurance re complex tests and next steps OR Direct biopsy option TRIAGE by radiology or respiratory medicine according to local protocol. Lung cancer likely?"
                            : "Schnellzugang zur Lungenkrebs-Klinik. Diagnostischer Prozessplan / diagnostisches Planungstreffen vor der Klinik. Behandlung von Begleiterkrankungen und Palliativversorgung / Symptombehandlung. LCNS: Prähabilitation/soziale/psychologische Bewertung. Klarheit/Beruhigung in Bezug auf komplexe Tests und nächste Schritte ODER Direkte Biopsie-Option. TRIAGE durch Radiologie oder Atemwegsmedizin nach lokalem Protokoll. Lungenkrebs wahrscheinlich?") ? (
                            <>
                              <Tooltip
                                placement="top"
                                title={
                                  lang == "eng"
                                    ? `Fast track lung cancer clinic. Diagnostic process plan / diagnostic planning meeting prior to clinic. Treatment of co-morbidity and palliation / treatment of symptoms. LCNS: Prehabilitation/social/psychological assessment. Clarity/reassurance re complex tests and next steps`
                                    : `Schnellzugang zur Lungenkrebs-Klinik. Diagnostischer Prozessplan / diagnostisches Planungstreffen vor der Klinik. Behandlung von Begleiterkrankungen und Palliativversorgung / Symptombehandlung. LCNS: Prähabilitation/soziale/psychologische Bewertung. Klarheit/Beruhigung in Bezug auf komplexe Tests und nächste Schritte`
                                }
                              >
                                <Button
                                  className="small-btn"
                                  onClick={() =>
                                    handleSubmitLungCancer(
                                      lang == "eng"
                                        ? `Fast track lung cancer clinic. Diagnostic process plan / diagnostic planning meeting prior to clinic. Treatment of co-morbidity and palliation / treatment of symptoms. LCNS: Prehabilitation/social/psychological assessment. Clarity/reassurance re complex tests and next steps`
                                        : `Schnellzugang zur Lungenkrebs-Klinik. Diagnostischer Prozessplan / diagnostisches Planungstreffen vor der Klinik. Behandlung von Begleiterkrankungen und Palliativversorgung / Symptombehandlung. LCNS: Prähabilitation/soziale/psychologische Bewertung. Klarheit/Beruhigung in Bezug auf komplexe Tests und nächste Schritte`
                                    )
                                  }
                                >
                                  {lang == "eng"
                                    ? `Fast track lung cancer clinic. Diagnostic process plan / diagnostic planning meeting prior to clinic. Treatment of co-morbidity and palliation / treatment of symptoms. LCNS: Prehabilitation/social/psychological assessment. Clarity/reassurance re complex tests and next steps`
                                    : `Schnellzugang zur Lungenkrebs-Klinik. Diagnostischer Prozessplan / diagnostisches Planungstreffen vor der Klinik. Behandlung von Begleiterkrankungen und Palliativversorgung / Symptombehandlung. LCNS: Prähabilitation/soziale/psychologische Bewertung. Klarheit/Beruhigung in Bezug auf komplexe Tests und nächste Schritte`}
                                </Button>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title="Direct biopsy option TRIAGE by radiology or respiratory medicine according to local protocol. Lung cancer likely?"
                              >
                                <Button
                                  className="small-btn"
                                  onClick={() =>
                                    handleSubmitLungCancer(
                                      lang == "eng"
                                        ? `Direct biopsy option TRIAGE by radiology or respiratory medicine according to local protocol. Lung cancer likely?`
                                        : `Direkte Biopsie-Option. TRIAGE durch Radiologie oder Atemwegsmedizin nach lokalem Protokoll. Lungenkrebs wahrscheinlich?`
                                    )
                                  }
                                >
                                  {lang == "eng"
                                    ? `Direct biopsy option TRIAGE by radiology or respiratory medicine according to local protocol. Lung cancer likely?`
                                    : `Direkte Biopsie-Option. TRIAGE durch Radiologie oder Atemwegsmedizin nach lokalem Protokoll. Lungenkrebs wahrscheinlich?`}
                                </Button>
                              </Tooltip>
                            </>
                          ) : updatedResp[updatedResp.length - 1]?.content ==
                            (lang == "eng"
                              ? "Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by primary care OR Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by secondary care"
                              : `Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Primärversorgung ODER Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Sekundärversorgung`) ? (
                            <>
                              <Tooltip
                                placement="top"
                                title={
                                  lang == "eng"
                                    ? "Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by primary care OR Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by secondary care"
                                    : ` Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Primärversorgung`
                                }
                              >
                                <Button
                                  className="small-btn"
                                  onClick={() =>
                                    handleSubmitLungCancer(
                                      lang == "eng"
                                        ? "Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by primary care"
                                        : `Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Primärversorgung`
                                    )
                                  }
                                >
                                  {lang == "eng"
                                    ? "Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by primary care"
                                    : `Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Primärversorgung`}
                                </Button>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title={
                                  lang == "eng"
                                    ? `Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by secondary care`
                                    : `Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Sekundärversorgung`
                                }
                              >
                                <Button
                                  className="small-btn"
                                  onClick={() =>
                                    handleSubmitLungCancer(
                                      lang == "eng"
                                        ? `Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by secondary care`
                                        : `Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Sekundärversorgung`
                                    )
                                  }
                                >
                                  {lang == "eng"
                                    ? `Lung cancer unlikely (*1 &2) Further management according to local protocol with options of further management of CT findings by secondary care`
                                    : `Lungenkrebs unwahrscheinlich (*1 & 2) Weitere Verwaltung gemäß lokalem Protokoll mit Optionen zur weiteren Verwaltung von CT-Befunden durch die Sekundärversorgung`}
                                </Button>
                              </Tooltip>
                            </>
                          ) : updatedResp[
                              updatedResp.length - 1
                            ]?.content.includes(
                              lang == "eng"
                                ? "Full MDT discussion of treatment options "
                                : `Volle MDT-Diskussion`
                            ) ||
                            updatedResp[updatedResp.length - 1]?.content ==
                              (lang == "eng"
                                ? "Full MDT discussion of treatment options"
                                : `Volle MDT-Diskussion`) ? (
                            <>
                              <Button
                                className="small-btn"
                                onClick={() =>
                                  handleSubmitLungCancer(
                                    lang == "eng"
                                      ? `Cancer detected?`
                                      : `Krebs festgestellt?`
                                  )
                                }
                              >
                                {lang == "eng"
                                  ? `Cancer detected ?`
                                  : `Krebs festgestellt?`}
                              </Button>
                              <Button
                                className="small-btn"
                                onClick={() =>
                                  handleSubmitLungCancer(
                                    lang == "eng"
                                      ? `No Cancer detected?`
                                      : `Kein Krebs festgestellt?`
                                  )
                                }
                              >
                                {lang == "eng"
                                  ? `No Cancer detected ?`
                                  : `Kein Krebs festgestellt?`}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className="small-btn"
                                onClick={() =>
                                  handleSubmitLungCancer(
                                    lang == "eng" ? `yes` : `ja`
                                  )
                                }
                              >
                                {lang == "eng" ? `Yes` : `Ja`}
                              </Button>
                              <Button
                                className="small-btn"
                                onClick={() =>
                                  handleSubmitLungCancer(
                                    lang == "eng" ? `no` : `nein`
                                  )
                                }
                              >
                                {lang == "eng" ? `No` : `NEIN`}
                              </Button>
                            </>
                          )}
                        </div>
                      )}
                  </>
                )}
              </ScrollableFeed>
            </div>
            <div className="search-container">
              <div className="search-box">
                <TextField
                  className="input-box"
                  hiddenLabel
                  multiline
                  value={text}
                  placeholder="Type your query here "
                  // label="Text"
                  // rows={4}            margin-bottom: 1%;

                  // defaultValue="Normal"
                  // variant="filled"
                  disabled={typing}
                  onKeyDown={(e) => {
                    handleSubmit(e, "chat");
                  }}
                  onChange={handleChange}
                />

                {listening ? (
                  <img src={speaking} alt="speaking" className="speaking"></img>
                ) : (
                  <FontAwesomeIcon
                    onClick={handleVoiceSpeech}
                    icon={faMicrophone}
                    style={{ color: "#153359" }}
                    className="microphone"
                  />
                )}

                {loading === "chat" ? (
                  // <img
                  //   style={{ width: "4.5%" }}
                  //   src={require("../../assets/loader.gif")}
                  //   alt=""
                  // />
                  <CircularProgress />
                ) : (
                  <img
                    style={{ cursor: "pointer" }}
                    src={sendIcon}
                    alt=""
                    onClick={(e) => {
                      handleSubmit(null, "chat");
                    }}
                  />
                )}
              </div>

              <Button
                disabled={updatedResp.length < 2}
                loading={loading === "summerize"}
                className="summaryBtn"
                onClick={(e) => handleSubmit(null, "summerize")}
              >
                {"Summary"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Summary open={open} setOpen={setOpen} data={summary}></Summary>
    </>
  );
};

export default BiobotChat;
