// import React from "react";
// import { Navigate } from "react-router-dom";
// import { checkIfLogin } from "../../utils/sessionManagement";

// const AuthRoute = ({ children }) => {
//   if (!checkIfLogin()) {
//     return <Navigate to="/" />;
//   } else {
//     return children;
//   }
// };

// export default AuthRoute;

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkIfLogin } from "../../utils/sessionManagement";
const AuthRoutes = () => {
  return checkIfLogin() ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/",
      }}
    />
  );
};

export default AuthRoutes;
