import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./index.scss";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import bot from "../../assets/Bot_img.svg";
import web from "../../assets/web_img.svg";
import cross from "../../assets/cross.svg";

import { useNavigate } from "react-router-dom";
import { getAllBots } from "../../redux/apiCall/fetchAllBots";
import { deleteBotApiCall } from "../../redux/apiCall/deleteBotApiCall";

import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CardMedia,
  Hidden,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { startCreateBot } from "../../redux/apiCall/createBotApiCall";
import { getData } from "../../utils/storageService";
import { LoadingButton } from "@mui/lab";
import Item from "antd/es/list/Item";
import { addSession } from "../../utils/sessionManagement";
const AllBot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: createBotData, status: createBotStatus } = useSelector(
    (state) => state.createBotData?.data
  );

  const { data: deleteBotData, status: deleteBotStatus } = useSelector(
    (state) => state.deleteBotData?.data
  );

  const [open, setOpen] = useState(false);
  const [botName, setBotName] = useState("");
  const createBotName = () => {
    dispatch(startCreateBot({ botName, email: getData("Email") }));
  };

  const deleteBot = (e, bot) => {
    e.stopPropagation();
    dispatch(deleteBotApiCall({ botID: bot.botID }));
  };
  const handleModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onCardClick = (bot) => {
    addSession(bot);
    navigate("/steps", { state: { cardClick: true } });
  };

  useEffect(() => {
    if (createBotStatus === 200) {
      navigate("/steps");
      handleClose();
    }
    dispatch(getAllBots());
  }, [createBotData, deleteBotData]);

  const { data: allBots } = useSelector((state) => state.allBots);

  const renderCards = () => {
    return allBots?.bots?.map((e, idx) => {
      return (
        <>
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            key={idx}
            onClick={() => onCardClick(e)}
            style={{ position: "relative" }}
          >
            <Item>
              <Card className="bot-card">
                <img src={bot} style={{ width: "70%" }}></img>
                <CardContent className="bot-card-content">
                  <div style={{ color: "rgba(1, 26, 89, 1)" }}>
                    {e?.botName}
                  </div>
                  <img src={web} alt=""></img>
                  <p
                    style={{
                      color: "rgba(1, 26, 89, 1)",
                      backgroundColor: "#fff",
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      borderRadius: "5px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {` ${e?.websiteURL}`}
                  </p>
                </CardContent>
              </Card>
            </Item>
            <img
              src={cross}
              alt=""
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={(d) => deleteBot(d, e)}
            />
          </Grid>

          {/* <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Item>
                <Card className="bot-card">
                  <CardMedia image={bot} sx={{ height: "90px" }}></CardMedia>
                  <CardContent className="bot-card-content">
                
                    <div style={{ color: "rgba(1, 26, 89, 1)" }}>
                      {e?.botName}
                    </div>
                    <img src={web} alt=""></img>
                    <p
                      style={{
                        color: "rgba(1, 26, 89, 1)",
                        backgroundColor: "#fff",
                        paddingLeft: "8%",
                        paddingRight: "8%",
                        borderRadius: "5px",
                      }}
                    >
                      {` ${e?.websiteURL}`}
                    </p>
                  </CardContent>
                </Card>
              </Item>
            </Grid> */}
          {/* <Grid item xs={4} sm={4} md={4} lg={4}>
            <Card className="bot-card">
              <CardMedia image={bot} sx={{ height: "90px" }}></CardMedia>
              <CardContent className="bot-card-content">
                <div style={{ color: "rgba(1, 26, 89, 1)" }}>{e?.botName}</div>
                <img src={web} alt=""></img>
                <p
                  style={{
                    color: "rgba(1, 26, 89, 1)",
                    backgroundColor: "#fff",
                    paddingLeft: "8%",
                    paddingRight: "8%",
                    borderRadius: "5px",
                  }}
                >
                  {` ${e?.websiteURL}`}
                </p>
              </CardContent>
            </Card>
          </Grid> */}
          {/* </Grid> */}
        </>
      );
    });
  };

  const modalContent = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        height: 200,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p className="enter-name">Enter a name for your bot</p>
        <TextField
          hiddenLabel
          id="filled-hidden-label-normal"
          value={botName}
          className="name-textfield"
          onChange={(e) => {
            setBotName(e.target.value);
          }}
          style={{ width: "400px", border: "1px solid rgba(0, 26, 92, 1)" }}
        />
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={createBotData?.loading}
          className="done-btn"
          onClick={createBotName}
          style={{ margin: "4%", width: "150px" }}
        >
          Done
        </LoadingButton>
      </div>
    </Box>
  );
  return (
    <div className="allBot">
      <div className="card-box">
        <Grid item className="create-button-box">
          <Button className="create-btn" onClick={handleModal}>
            <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon> &nbsp;
            Create Bot
          </Button>
        </Grid>

        <Box
          sx={{ flexGrow: 1 }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              width: "70%",
              height: "65vh",
              overflow: "auto",
              padding: "1% 0 3% 3%",
            }}
          >
            {renderCards()}
          </Grid>
        </Box>
      </div>
      <Modal open={open} onClose={handleClose}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default AllBot;
