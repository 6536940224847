import { Button } from "antd";
import React, { useEffect, useState } from "react";
import pbResponseAILogo from "../../assets/pbResponseAI.png";
import CERC_Logo from "../../assets/CERC_Logo.png";
import "./navbar.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { checkIfLogin, clearSession } from "../../utils/sessionManagement";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const Navbar = ({ setOpen, setSignFlag, tabValue, setTabValue }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleClickOpen = (value) => {
    if (value === "signIn") {
      setSignFlag(true);
    } else {
      setSignFlag(false);
    }
    setOpen(true);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const Logout = () => {
    clearSession();
    sessionStorage.clear();
    setIsLoggedIn(false);
    toast.success("Logged out successfully");
  };
  const check = checkIfLogin();

  useEffect(() => {
    if (check) {
      setIsLoggedIn(true);
    } else {
      // navigate("/");
    }
  }, [check, location]);

  return (
    <div className="navbar">
      <div className="leftSection">
        <img
          src={CERC_Logo}
          alt="pb-responseai"
          className="pbResponseAILogo"
          onClick={() => navigate("/")}
        />

        {isLoggedIn && location.pathname !== "/allBots" ? <></> : ""}
      </div>
      <h1>Central Electricity Regulatory Commission</h1>
      <div className="rightSection">
        {isLoggedIn ? (
          <Button
            variant="contained"
            className="signIn"
            onClick={() => Logout()}
          >
            Sign out
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              className="signUp"
              onClick={() => handleClickOpen("signUp")}
            >
              Sign In
            </Button>
            <br></br>
            <Button
              variant="contained"
              className="signIn"
              onClick={() => handleClickOpen("signIn")}
            >
              Sign Up{" "}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
