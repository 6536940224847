// import axios from "axios";
// import { getAuthHeader } from "./sessionManagement";

// const apiClient = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_URL,
// });

// export const setHeader = (token) => {
//   apiClient.defaults.headers.common.Authorization = token;
// };

// export const setCookie = (cookie) => {
//   apiClient.defaults.headers.common.Cookie = cookie;
// };

// export const discardHeader = () => {
//   delete apiClient.defaults.headers.common.Authorization;
//   delete apiClient.defaults.headers.common.Cookie; // Remove the cookie header
// };

// apiClient.defaults.headers.common.Authorization = getAuthHeader();

// apiClient.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// apiClient.defaults.headers.common.Cookie = getAuthHeader("Cookie");

// /**
//  * @type {import("axios").AxiosInstance}
//  */
// export default apiClient;

import axios from "axios";
import { getAuthHeader } from "./sessionManagement";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // withCredentials: true, // Enable sending cookies
});

export const setHeader = (token) => {
  apiClient.defaults.headers.common.Authorization = token;
};

export const setCookie = (cookie) => {
  apiClient.defaults.headers.common.Cookie = cookie;
};

export const discardHeader = () => {
  delete apiClient.defaults.headers.common.Authorization;
  delete apiClient.defaults.headers.common.Cookie;
};

apiClient.defaults.headers.common.Authorization = getAuthHeader("Token");
apiClient.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// apiClient.defaults.headers.common.Cookie = getAuthHeader("Cookie");

export default apiClient;
