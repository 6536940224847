const fetchBotsSuccess = (payload) => ({
  type: "FETCH_BOTS_SUCCESS",
  payload,
});

const fetchBotsFailure = (error) => ({
  type: "FETCH_BOTS_FAILURE",
  error,
});

const resetfetchBotsFlag = () => ({ type: "FETCH_BOTS_RESET" });

const fetchBotsApiCall = () => ({
  type: "FETCH_BOTS_API_CALL",
});

export {
  fetchBotsApiCall,
  fetchBotsFailure,
  fetchBotsSuccess,
  resetfetchBotsFlag,
};
