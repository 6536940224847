import React from "react";
import BiobotChat from "./BiobotChat";

const BioBotReplica = () => {
  return (
    <div className="bioBot">
      <BiobotChat />
    </div>
  );
};

export default BioBotReplica;
