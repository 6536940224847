export const apiConfig = {
  GET_ALL_BOT: {
    method: "GET",
    url: "/apifetchBots",
    data: {},
  },

  DELETE_BOT: {
    method: "DELETE",
    url: "/apideleteBot",
    data: {},
  },
};
