import React, { useState } from "react";
import "./index.scss";
import { Button } from "@mui/material";

const QuestionComponent = ({ question, onNextQuestion }) => {
  console.log(question, "question");
  const [selectedChoice, setSelectedChoice] = useState(null);

  const handleChoiceSelect = (choice) => {
    console.log();
    setSelectedChoice(choice);
  };

  const handleNextQuestion = () => {
    onNextQuestion(selectedChoice);
    setSelectedChoice(null);
  };

  const correctChoice = question.correct_choice;

  return (
    <div
      style={{
        margin: "10%",
      }}
    >
      <div className="question-container">
        <h3>{question.question}</h3>
        <ul>
          {Object.entries(question.choices).map(([key, choice]) => (
            <li key={key}>
              <label>
                <input
                  type="radio"
                  name={`question_${question}`}
                  value={key}
                  onChange={() => handleChoiceSelect(choice)}
                  checked={selectedChoice === choice}
                  style={{ marginRight: "10px" }}
                />
                {choice}
              </label>
            </li>
          ))}
        </ul>

        {/* {selectedChoice && (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <span className="correct-option">Correct option:</span>
            <p className="correct-choice">{correctChoice}</p>
          </div>
        )} */}

        <Button
          variant="contained"
          disabled={!selectedChoice}
          onClick={handleNextQuestion}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default QuestionComponent;
