import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import "./index.scss";
import loading from "../../../src/assets/loading.gif";

const ProgressBar = (props) => {
  const [progress, setProgress] = useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 95 ? 95 : prevProgress + 5
      );
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal open={true}>
      <div className="progress-wrap">
        <img src={loading} alt="" />
        {/* <div>Generating.....</div> */}

        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            sx={{
              backgroundColor: "#7bb9ff",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#094f9f",
              },
            }}
            value={progress}
          />
          <Box sx={{ minWidth: 35 }}>
            <Typography>{`${Math.round(progress)}%`}</Typography>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default ProgressBar;
