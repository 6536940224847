import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { usePDF, Margin, Resolution, PDF } from "react-to-pdf";

const Summary = ({ open, setOpen, data }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const modalData = data.split("\n");
  const renderSummary = () => {
    return modalData?.map((e, idx) => {
      return (
        <div key={idx}>
          {idx === 0 ? (
            <p
              style={{
                padding: "1%",
                textAlign: "left",
                fontWeight: "bold",
                // color: "#b7b7b7",
                fontFamily: `"Poppins", sans-serif`,
                fontSize: "18px",
              }}
            >
              {e}
            </p>
          ) : (
            <p
              style={{
                padding: "1%",
                textAlign: "left",
                // color: "#b7b7b7",
                fontFamily: `"Poppins", sans-serif`,
                fontSize: "18px",
              }}
            >
              {e}
            </p>
          )}
        </div>
      );
    });
  };
  const { toPDF, targetRef } = usePDF({
    filename: "summary.pdf",
    method: "save",
    resolution: Resolution.HIGH,
    page: { margin: Margin.MEDIUM, orientation: "portrait" },
  });

  return (
    <>
      {modalData && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              padding: "20px 0px ",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "800px",
              // overflow: "auto",
              //   maxWidth: 750,
              minHeight: 400,
              bgcolor: "background.paper",
              border: "2px solid rgba(0, 116, 252, 0.60)",
              boxShadow: 24,
              borderRadius: "10px",
              // p: 3,
              maxHeight: 700,
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  fontSize: "24px",
                  padding: "0% 4% ",
                  textAlign: "center",
                  color: "rgba(1, 26, 89, 1)",
                  fontFamily: `"Poppins", sans-serif`,
                  fontWeight: "700",
                  gap: "10px",
                }}
              >
                Summary
                <IconButton onClick={toPDF} color="primary">
                  <DownloadIcon />
                </IconButton>
              </div>
              <div
                style={{ margin: "3%", height: "420px", overflow: "auto" }}
                ref={targetRef}
              >
                {renderSummary()}
              </div>
              <div
                style={{
                  // paddingBottom: "4%",
                  display: "flex",
                  alignSelf: "center",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    width: 50,
                    fontSize: "16px",
                    fontFamily: `"Poppins", sans-serif`,
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    background: "#0074fc",
                  }}
                  onClick={handleClose}
                >
                  OK
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Summary;
