import React, { useState } from "react";
import arrow from "../../assets/Vector.svg";
import { useLocation, useNavigate } from "react-router-dom";
import qr from "../../assets/SurveyQR.png";
import pdf from "../../assets/QRpdf.png";
import query from "../../assets/QRquery.png";
import { Button, CircularProgress } from "@mui/material";
// import backArrow from "../../assets/backErrow.svg";

const ShowQR = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state?.data;
  const newData = location?.state?.newData;
  const [generateTest, setGenerateTest] = useState(false);

  console.log("state", newData?.image_bytes);
  const downloadImage = () => {
    // const link = document.createElement("a");
    // link.href = state === "query" ? query : pdf;
    // link.download = "survey_qr.png";
    // link.click();

    const link = document.createElement("a");

    const base64Image = newData?.image_bytes;

    if (base64Image) {
      const dataURL = `data:image/png;base64,${base64Image}`;

      link.href = dataURL;
      link.download = "survey_qr.png";
      link.click();
    }
  };
  const handleNavigate = () => {
    setGenerateTest(true);
    setTimeout(() => {
      navigate("/csvAnalysis");
    }, 3000);
  };
  return (
    <div className="show-qr">
      <img
        alt=""
        src={arrow}
        style={{ width: "40px", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      />
      <br />
      {state && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "70vh",
            justifyContent: "center",
          }}
        >
          <h2>Scan QR for Google Form Survey</h2>
          <br />
          {state === "query" ? (
            <>
              <img
                alt=""
                // src={query}
                src={`data:image/png;base64,${newData?.image_bytes}`}
                style={{ width: "250px", height: "250px" }}
              />
              <a
                // href="https://docs.google.com/forms/d/1FuWP9WbmrBHxOJZSWABRoeoAFCWt-SfH-qiX_l9rTws/viewform?ts=6579aa3a&edit_requested=true#responses.com/forms/d/1OiaKpH-OrtL9EX1l0Yo2qwtJ2LPpC9cYMkB07fGccDc/viewform?edit_requested=true#responses"
                // href="https://docs.google.com/forms/d/1FuWP9WbmrBHxOJZSWABRoeoAFCWt-SfH-qiX_l9rTws/edit?ts=6579aa3a#responses"
                href={newData?.response_url}
                target="_blank"
                style={{ marginTop: "5%" }}
              >
                {" "}
                Go To Google Form Responses
              </a>{" "}
            </>
          ) : (
            <>
              <img
                alt=""
                // src={pdf}
                src={`data:image/png;base64,${newData?.image_bytes}`}
                style={{ width: "250px", height: "250px" }}
              />
              {newData?.response_url && (
                <a
                  // href="https://docs.google.com/forms/d/1OiaKpH-OrtL9EX1l0Yo2qwtJ2LPpC9cYMkB07fGccDc/edit#responses"
                  href={newData?.response_url}
                  target="_blank"
                  style={{ marginTop: "5%" }}
                >
                  {" "}
                  Go To Google Form Responses
                </a>
              )}{" "}
            </>
          )}{" "}
          <br />
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "1%" }}>
        <Button variant="contained" onClick={handleNavigate}>
          {generateTest ? (
            <>
              Generating Insights... &nbsp;
              <CircularProgress color="inherit" size={20} />{" "}
            </>
          ) : (
            "View Survey Analytics"
          )}
        </Button>
        <Button variant="contained" onClick={downloadImage}>
          Download QR
        </Button>
      </div>
    </div>
  );
};

export default ShowQR;
