const uploadLinkSuccess = (payload) => ({
  type: "UPLOADLINK_SUCCESS",
  payload,
});

const uploadLinkFailure = (error) => ({
  type: "UPLOADLINK_FAILURE",
  error,
});

const resetuploadLinkState = () => ({ type: "UPLOADLINK_RESET" });

const uploadLinkApiCall = () => ({
  type: "UPLOADLINK_API_CALL",
});

export {
  uploadLinkApiCall,
  uploadLinkFailure,
  uploadLinkSuccess,
  resetuploadLinkState,
};
