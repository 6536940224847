import { toast } from "react-toastify";
import apiClient, { setHeader } from "../../utils/apiClient";
import { addSession } from "../../utils/sessionManagement";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";
import {
  uploadDocsCall,
  uploadDocsFailure,
  uploadDocsSuccess,
} from "../action/uploadDocsActions";
import { apiConfig } from "../apiConfig/uploadDocsApiConfig";

export const startuploadDocs = (payload) => (dispatch) => {
  const apiPayload = { ...apiConfig.UPLOAD };
  apiPayload.data = payload;
  dispatch(apiBegin());
  dispatch(uploadDocsCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(uploadDocsSuccess(res));
      dispatch(apiSuccess());
      toast.success("File Uploaded successfully");
    })
    .catch((err) => {
      dispatch(uploadDocsFailure(err));
      dispatch(apiFailure(err));
      toast.error("Something went wrong please try again");
    });
};
