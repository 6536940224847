const createBotSuccess = (payload) => ({
  type: "CREATE_BOT_SUCCESS",
  payload,
});

const createBotFailure = (error) => ({
  type: "CREATE_BOT_FAILURE",
  error,
});

const resetcreateBotState = () => ({ type: "CREATE_BOT_RESET" });

const createBotCall = () => ({
  type: "CREATE_BOT_API_CALL",
});

export {
  createBotSuccess,
  createBotFailure,
  resetcreateBotState,
  createBotCall,
};
