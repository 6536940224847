import { combineReducers } from "redux";
import { loginDataReducer } from "./loginReducer";
import { registerDataReducer } from "./registerReducer";
import { getuploadDocsDataReducer } from "./uploadDocsReducer";
import { webpageUploadLinkReducer } from "./webpageReducer";
import { fetchAllBotsReducer } from "./fetchAllBotsReducer";
import { getcreateBotDataReducer } from "./createBotReducer";
import { getSendMsgReducer } from "./chatReducer";
import { getdeleteBotDataReducer } from "./deleteBotReducer";
import { chatHistoryReducer } from "./historyReducer";

export default combineReducers({
  ...loginDataReducer,
  ...registerDataReducer,
  ...webpageUploadLinkReducer,
  ...getuploadDocsDataReducer,
  ...fetchAllBotsReducer,
  ...getcreateBotDataReducer,
  ...getSendMsgReducer,
  ...getdeleteBotDataReducer,
  ...chatHistoryReducer,
});
