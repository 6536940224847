import apiClient from "../../utils/apiClient";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";
import {
  sendMsgApiCall,
  sendMsgSuccess,
  sendMsgFailure,
} from "../action/chatAction";
import { apiConfig } from "../apiConfig/chatApiConfig";
export const chatApiCall = (payload) => (dispatch) => {
  let apiPayload = { ...apiConfig.sendMsg };
  apiPayload.data = payload;

  dispatch(apiBegin());
  dispatch(sendMsgApiCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(sendMsgSuccess(res.data));
    })
    .catch((err) => {
      dispatch(sendMsgFailure(err));
      dispatch(apiFailure(err));
    });
};
