import React, { useEffect, useState } from "react";

import { pdfjs, Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { PdfHighlighter, PdfLoader } from "@darian-lp/react-pdf-highlighter";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { CircularProgress } from "@mui/material";
import { isError } from "@tanstack/react-query";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDFHighlighter = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [error, setError] = useState(null);

  const pageRefs = React.useRef({});
  const itemsPerPage = 5;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsPdfLoaded(true);
    // pageRefs.current[23].scrollIntoView({ behavior: "smooth" });
  }
  function onDocumentLoadError(error) {
    console.log("eeeeee", error);
    setError(error); // Set the error state if loading fails
  }
  useEffect(() => {
    setTimeout(
      () =>
        pageRefs.current[Number(pdfUrl?.pageNo)]?.scrollIntoView({
          // behavior: "smooth",
        }),
      calculateTimeToDisplayPdfViewer() * 1000
    );
  });
  const calculateTimeToDisplayPdfViewer = () => {
    // const numberOfItems = data?.items?.length;
    console.log(numPages);
    const numberOfItems = numPages;
    if (numberOfItems > 0 && itemsPerPage > 0) {
      let time = numberOfItems / itemsPerPage / 5; // 1 page = 1/5s = 0.20s
      time = parseFloat(time.toFixed(2));
      console.log("Waiting " + time + "s.");
      return time;
    } else {
      console.log("Waiting " + 2 + "s.");
      return 2.0;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100vh",
      }}
    >
      <div>
        {(!isPdfLoaded || !isError) && (
          <center>
            <div>
              <CircularProgress />
            </div>
          </center>
        )}
        <Document
          file={pdfUrl?.docLink}
          onLoadSuccess={onDocumentLoadSuccess}
          scale={{ height: 1 }}
          onLoadError={onDocumentLoadError}
        >
          {Array(...Array(numPages))
            .map((x, i) => i) //i+1
            .map((page) => (
              <div
                key={page}
                ref={(el) => {
                  pageRefs.current[page] = el;
                }}
                // style={{ scrollBehavior: "revert-layer" }}
              >
                <Page
                  pageNumber={page}
                  width={"1024"}
                  // width={window.innerWidth} // Set the width of the page to the window width or any specific width you desire
                  height={window.innerHeight}
                  loading=""
                />
              </div>
            ))}
        </Document>
      </div>
    </div>
  );
};

export default PDFHighlighter;

// import React, { useEffect, useState, useRef } from "react";
// import { pdfjs, Document, Page } from "react-pdf";
// import { CircularProgress } from "@mui/material";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const PDFHighlighter = ({ pdfUrl, pageNumber }) => {
//   const coordinates = {
//     x: 71.53920000000001,
//     y: 565.1712,
//     width: 468.6624,
//     height: 151.20000000000005,
//   };

//   const [numPages, setNumPages] = useState(null);
//   const [isPdfLoaded, setIsPdfLoaded] = useState(false);
//   const containerRef = useRef(null);

//   const scrollToCoordinates = () => {
//     if (containerRef.current && pageNumber && coordinates) {
//       const { x, y, width, height } = coordinates;
//       const scale = containerRef.current.clientWidth / width; // Calculate scale factor
//       const offsetX = x * scale;
//       const offsetY = y * scale;
//       containerRef.current.scrollTo({
//         left: offsetX,
//         top: offsetY,
//         behavior: "smooth",
//       });
//     }
//   };

//   useEffect(() => {
//     const loadPdf = async () => {
//       try {
//         const pdf = await pdfjs.getDocument(pdfUrl.docLink).promise;
//         setNumPages(pdf.numPages);
//         setIsPdfLoaded(true);
//       } catch (error) {
//         console.error("Error loading PDF: ", error);
//       }
//     };

//     if (pdfUrl) {
//       loadPdf();
//     }
//   }, [pdfUrl]);

//   useEffect(() => {
//     if (pageNumber && coordinates) {
//       scrollToCoordinates();
//     }
//   }, [pageNumber, coordinates]);

//   return (
//     <div
//       ref={containerRef}
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         overflow: "auto",
//         // height: "500px",
//       }}
//     >
//       {(!isPdfLoaded || !pdfUrl) && (
//         <center>
//           <div>
//             <CircularProgress />
//           </div>
//         </center>
//       )}
//       {pdfUrl && numPages && (
//         <Document file={pdfUrl.docLink} onLoadSuccess={() => {}}>
//           {Array.from(new Array(numPages), (el, index) => (
//             <div key={`page_${index + 1}`} className="pdf-page">
//               <Page
//                 pageNumber={index + 1}
//                 width={containerRef.current?.clientWidth}
//                 renderAnnotationLayer={false}
//                 renderTextLayer={false}
//               />
//             </div>
//           ))}
//         </Document>
//       )}
//     </div>
//   );
// };

// export default PDFHighlighter;

// import React, { useEffect, useState, useRef } from "react";
// import { pdfjs, Document, Page } from "react-pdf";
// import { CircularProgress } from "@mui/material";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const PDFHighlighter = ({ pdfUrl, pageNumber }) => {
//   const coordinates = {
//     x: 71.53920000000001,
//     y: 565.1712,
//     width: 468.6624,
//     height: 151.20000000000005,
//   };

//   const [numPages, setNumPages] = useState(null);
//   const [isPdfLoaded, setIsPdfLoaded] = useState(false);
//   const containerRef = useRef(null);

//   const scrollToCoordinates = () => {
//     if (containerRef.current && pageNumber && coordinates) {
//       const { x, y, width, height } = coordinates;
//       const scale = containerRef.current.clientWidth / width; // Calculate scale factor
//       const offsetX = x * scale;
//       const offsetY = y * scale;
//       containerRef.current.scrollTo({
//         left: offsetX,
//         top: offsetY,
//         behavior: "smooth",
//       });
//     }
//   };

//   useEffect(() => {
//     const loadPdf = async () => {
//       try {
//         const pdf = await pdfjs.getDocument(pdfUrl.docLink).promise;
//         setNumPages(pdf.numPages);
//         setIsPdfLoaded(true);
//       } catch (error) {
//         console.error("Error loading PDF: ", error);
//       }
//     };

//     if (pdfUrl) {
//       loadPdf();
//     }
//   }, [pdfUrl]);

//   useEffect(() => {
//     if (pageNumber && coordinates) {
//       scrollToCoordinates();
//     }
//   }, [pageNumber, coordinates]);

//   return (
//     <div
//       ref={containerRef}
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         overflow: "auto",
//         height: "500px",
//       }}
//     >
//       {(!isPdfLoaded || !pdfUrl) && (
//         <center>
//           <div>
//             <CircularProgress />
//           </div>
//         </center>
//       )}
//       {pdfUrl && numPages && (
//         <Document file={pdfUrl.docLink} onLoadSuccess={() => {}}>
//           {Array.from(new Array(numPages), (el, index) => (
//             <div key={`page_${index + 1}`} className="pdf-page">
//               <Page
//                 pageNumber={index + 1}
//                 width={containerRef.current?.clientWidth}
//                 renderAnnotationLayer={false}
//                 renderTextLayer={false}
//               />
//             </div>
//           ))}
//         </Document>
//       )}
//     </div>
//   );
// };

// export default PDFHighlighter;
