import { toast } from "react-toastify";
import apiClient from "../../utils/apiClient";
import { getAuthHeader } from "../../utils/sessionManagement";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";

import {
  uploadLinkApiCall,
  uploadLinkSuccess,
  uploadLinkFailure,
} from "../action/webpageAction";
import { apiConfig } from "../apiConfig/webpageConfig";
export const submitUploadLinkCall = (payload) => (dispatch) => {
  let apiPayload = { ...apiConfig.LINK_UPLOAD };
  // apiPayload.url += `?webURL:${key}`;
  apiPayload.data = payload;
  dispatch(apiBegin());
  dispatch(uploadLinkApiCall());
  apiClient(apiPayload)
    .then((res) => {
      dispatch(uploadLinkSuccess(res));
      toast.success(res?.data?.msg);
    })
    .catch((err) => {
      dispatch(uploadLinkFailure(err));
      dispatch(apiFailure(err));
      toast.error("something went wrong please try again");
    });
};
