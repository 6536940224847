const uploadDocsSuccess = (payload) => ({
  type: "UPLOAD_DOCS_SUCCESS",
  payload,
});

const uploadDocsFailure = (error) => ({
  type: "UPLOAD_DOCS_FAILURE",
  error,
});

const resetuploadDocsState = () => ({ type: "UPLOAD_DOCS_RESET" });

const uploadDocsCall = () => ({
  type: "UPLOAD_DOCS_API_CALL",
});

export {
  uploadDocsSuccess,
  uploadDocsFailure,
  resetuploadDocsState,
  uploadDocsCall,
};
