import React from "react";
import BiobotChat from "./BiobotChat";

const BioBot = () => {
  return (
    <div className="bioBot">
      <BiobotChat />
    </div>
  );
};

export default BioBot;
