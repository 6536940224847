import React, { useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@mui/material";
import { startuploadDocs } from "../../redux/apiCall/uploadDocsApi";
import { useDispatch, useSelector } from "react-redux";
import { getAuthHeader } from "../../utils/sessionManagement";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { getData } from "../../utils/storageService";
import { useQuery } from "@tanstack/react-query";

const DocumentUploader = ({ setActiveStep }) => {
  const { data: uploadData, loading: uploadLoading } = useSelector(
    (state) => state.uploadDocsData
  );
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const [file, setFile] = useState();

  const handleFileUpload = async (files) => {
    if (files.length === 0) {
      toast.warn("please select file!");
      return;
    }
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("botID", getData("botID"));

    try {
      setUploading(true);
      dispatch(startuploadDocs(formData));
      setUploadedFile(files[0]);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }

    // const { isLoading, error, data } = useQuery({
    //   queryKey: ["uploadData"],
    //   queryFn: () => dispatch(startuploadDocs(formData)),
    //   // fetch("https://api.github.com/repos/TanStack/query").then((res) =>
    //   //   res.json()
    //   // ),
    // });

    // if (isLoading) return "Loading...";

    // if (error) return "An error has occurred: " + error.message;
  };

  const useStyles = makeStyles((theme) =>
    createStyles({
      previewChip: {
        minWidth: 160,
        maxWidth: 210,
      },
    })
  );

  const classes = useStyles();

  return (
    <div>
      {/* Original Dropzone with hidden UI */}
      <DropzoneArea
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Selected files"
        showAlerts={false}
        acceptedFiles={[
          "application/pdf",
          "application/vnd.google-apps.document",
          "application/vnd.google-apps.spreadsheet",
        ]}
        dropzoneText={
          <div className="insideDropzone">
            <span style={{ fontSize: "18px" }}>Drag and Drop file here</span>
            <h6 style={{ color: "#A8A8A8", margin: "0" }}>Limit 200 mb</h6>
            <small style={{ color: "#153359", fontSize: "14px" }}>
              Browse File
            </small>
          </div>
        }
        onChange={(file) => setFile(file)}
        filesLimit={1}
        maxFileSize={200 * 1024 * 1024}
      />

      <div className="uploadBtn">
        <LoadingButton
          variant="contained"
          color="primary"
          loading={uploadLoading}
          onClick={() => handleFileUpload(file)}
        >
          Upload
        </LoadingButton>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setActiveStep(2)}
        >
          Skip
        </Button>
      </div>
      {uploading && <CircularProgress />}

      {uploading && <CircularProgress />}
    </div>
  );
};

export default DocumentUploader;
