import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import arrow from "../../assets/Vector.svg";
import "./index.scss";
import { InputAdornment, TextField } from "@mui/material";
// import PdfViewer from "./PdfPreview";
import axios from "axios";
import PdfViewer from "../Survey/PdfPreview";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ProgressBar from "../../components/ProgressBar";

const Accreditation = () => {
  const navigate = useNavigate();
  const [showHeading, setShowHeading] = useState(true);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summaryValue, setSummaryValue] = useState("1");
  const [summary, setSummary] = useState("");
  const [companyInfo, setCompanyInfo] = useState();
  const [pastExperience, setPastExperience] = useState();
  const [downloadDropdown, setDownloadDropdown] = useState(null);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [text, setText] = useState("");
  const [outputData, setOutputData] = useState("");
  const handleChangeSummary = (event, newValue) => {
    console.log(newValue);
    setSummaryValue(newValue);
  };

  const handleFileUpload = async (file) => {
    setFiles(file);
    // setFiles(file);
    console.log("files", files);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    // files.forEach((file) => {
    //   formData.append("files", file);
    // });
    // formData.append("files", files[0]);
    files?.forEach((file) => {
      formData.append("files[]", file);
    });

    // formData.append("pageNo", text);
    // console.log("formdata", formData.getAll("files[]"));
    try {
      const response = await axios.post(
        `https://demo.salesbot.perpetualblock.io/api/processAccradation`,
        // ` http://192.168.10.167:3060/api/processAccradation`,
        formData,
        headers
      );

      // console.log(response?.data, "surveyquestion");
      setOutputData(response?.data?.outputData);
      setSummary(response?.data?.summary);
      setCompanyInfo(response?.data?.auth_df);
      setPastExperience(response?.data?.exp_df);
      setDownloadLinks({
        entireSummary: response?.data?.entire_summary,
        indiviualSummary: response?.data?.individual_summary,
        companyCSV: response?.data?.company_csv,
        pastExpCSV: response?.data?.pastexp_csv,
      });
      setLoading(false);
      setShowHeading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const OpenDownloadDropdown = (event) => {
    setDownloadDropdown(event.currentTarget);
  };

  const downloadFile = (fileLink) => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = fileLink;
    link.click();
    setDownloadDropdown(null);
  };

  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 95 ? 95 : prevProgress + 5
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      {loading && <ProgressBar showProgress={true} />}
      {/* <Modal open={loading}>
        <div>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
          <LinearProgress variant="determinate" />
        </div>
      </Modal> */}

      {showHeading ? (
        <div className="pdf-page">
          <img
            alt=""
            src={arrow}
            className="arrow-btn"
            onClick={() => navigate(-1)}
          ></img>{" "}
          {/* <div className="heading">
            <div className="heading-title">Generate Survey Form</div>
            <div className="heading-summary">
              Enter a Query or upload a document to generate survey
            </div>
          </div> */}
          <div className="upload-section">
            <div className="upload-section-dotted">
              <div>
                <DropzoneArea
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  previewGridProps={{
                    container: { spacing: 1, direction: "row" },
                  }}
                  showAlerts={false}
                  // acceptedFiles={[
                  //   "application/pdf",
                  //   // "application/vnd.google-apps.document",
                  //   // "application/vnd.google-apps.spreadsheet",
                  //   // "application/msword",
                  // ]}
                  dropzoneText={
                    <div className="insideDropzone">
                      <div
                        style={{
                          fontSize: "22px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          marginTop: "20px",
                        }}
                      >
                        Drag and Drop File here or
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontStyle: "italic",
                          color: "#ffffff",
                        }}
                      >
                        Supportable file : PDF, Image
                      </div>
                      <Button className="file-btn">Select from Device </Button>
                    </div>
                  }
                  onChange={(files) => handleFileUpload(files)}
                  filesLimit={100}
                  maxFileSize={200 * 1024 * 1024}
                  useChipsForPreview
                  previewChipProps={{
                    style: {
                      minWidth: "160px",
                      maxWidth: "160px",
                      // Add other styles as needed
                    },
                  }}
                  previewText="Selected files"
                />
              </div>
            </div>
          </div>
          {/* <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <TextField
              className="research-searchbar"
              placeholder="Enter Page No."
              variant="outlined"
              multiline={false}
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                margin: "1%",
                width: "25%",
                backgroundColor: "#ffffff",
                borderRadius: "100px",
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "100px",
                },
              }}
              value={text}
              onKeyDown={(e) => {
                if (!e.shiftKey && e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => setText(e?.target?.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </div> */}
          <div className="btn-div">
            {!loading && files.length > 0 ? (
              <Button
                variant="contained"
                className="file-btn"
                onClick={handleSubmit}
              >
                {/* <>
                  Generating... &nbsp;
                  <CircularProgress color="inherit" size={20} />{" "}
                </>
              ) : ( */}
                Submit
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="summary-wrap">
          <div
            style={{
              display: "flex",
              width: "88%",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={(e) => OpenDownloadDropdown(e)}>
              <FileDownloadOutlinedIcon
                style={{ color: "#ffffff", fontSize: "50px" }}
              />
            </Button>
          </div>
          <Menu
            id="download"
            anchorEl={downloadDropdown}
            keepMounted
            open={Boolean(downloadDropdown)}
            onClose={() => setDownloadDropdown(null)}
            sx={{ background: "#00000060" }}
          >
            <MenuItem
              className="list-item-download"
              onClick={() => downloadFile(downloadLinks?.entireSummary)}
            >
              Summary
            </MenuItem>
            <MenuItem
              className="list-item-download"
              onClick={() => downloadFile(downloadLinks?.indiviualSummary)}
            >
              Individual Summary
            </MenuItem>
            <MenuItem
              className="list-item-download"
              onClick={() => downloadFile(downloadLinks?.companyCSV)}
            >
              Company Information
            </MenuItem>
            <MenuItem
              className="list-item-download"
              onClick={() => downloadFile(downloadLinks?.pastExpCSV)}
            >
              Past Experience
            </MenuItem>
          </Menu>

          <div className="summary-container">
            <Box sx={{ width: "100%" }}>
              <TabContext value={summaryValue}>
                <Box>
                  <TabList
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#ffffff",
                      },
                    }}
                    onChange={handleChangeSummary}
                  >
                    <Tab
                      className="tab-name"
                      label="Past Experience"
                      value="3"
                    />
                    <Tab
                      className="tab-name"
                      label="Company Information"
                      value="2"
                    />
                    <Tab className="tab-name" label="Summary" value="1" />

                    <div className="title">Summary</div>
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <pre className="tab-content">{summary}</pre>
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <TableContainer sx={{ maxHeight: "60vh" }}>
                    <Table
                      // stickyHeader
                      // aria-label="sticky table"
                      sx={{ minWidth: 650 }}
                    >
                      <TableHead>
                        <TableRow>
                          {companyInfo?.columns?.map((item) => (
                            <TableCell
                              className="table-header-text"
                              align="center"
                            >
                              {item}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {companyInfo?.data?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {row?.map((it) => (
                              <TableCell
                                className="table-row-text"
                                align="center"
                              >
                                {it}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel value="3">
                  {" "}
                  <TableContainer sx={{ maxHeight: "60vh" }}>
                    <Table
                      // stickyHeader
                      // aria-label="sticky table"
                      sx={{ minWidth: 650 }}
                    >
                      <TableHead>
                        <TableRow
                        // width={item === "Item Details" ? 300 : "auto"}
                        >
                          {pastExperience?.columns?.map((item) => (
                            <TableCell
                              style={{
                                minWidth:
                                  item === "Item Details" ? "600px" : "auto",
                              }}
                              // width={item === "Item Details" ? 3000 : "auto"}
                              className="table-header-text"
                              align="center"
                            >
                              {item}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pastExperience?.data?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {row?.map((it) => (
                              <TableCell
                                className="table-row-text"
                                align="center"
                              >
                                {it}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          {/* <div className="summary-container">
            <table style={{ width: "80vw" }}>
              <thead>
                <tr style={{ display: "flex" }}>
                  <th>Item Name</th>
                  <th>NME - Status</th>
                </tr>
              </thead>
              <tbody classname="summary-table-body">
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {Object?.entries(outputData).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>
                        {value ? (
                          <span
                            style={{
                              color: "#34eb34",
                              justifyContent: "center",
                              paddingLeft: "60%",
                            }}
                          >
                            &#10004;
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              justifyContent: "center",
                              paddingLeft: "60%",
                            }}
                          >
                            &#10008;
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </div>
              </tbody>
            </table>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Accreditation;
