import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import arrow from "../../assets/Vector.svg";
import "./index.scss";
import PdfViewer from "./PdfPreview";
import axios from "axios";

const Survey = () => {
  const navigate = useNavigate();
  const [text, setText] = useState("");

  const [showHeading, setShowHeading] = useState(true);
  const [cardLoading, setCardLoading] = useState(false);

  const [qrLoad, SetQRLoad] = useState(false);

  const [files, setFiles] = useState([]);
  const handleFileUpload = async (files) => {
    console.log("uploading", files);
    setFiles(files);
    // const formData = new FormData();
    // formData.append("file", files[0]);

    setShowHeading(false);

    // setShowQuestions(true);
  };

  const generateQR = async () => {
    SetQRLoad(true);

    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/surveyquestion-pdf
        `,
        formData
      );

      console.log(response?.data?.form_id, "surveyquestion");
      localStorage.setItem("form_id", response?.data?.form_id);

      navigate("/surveyQR", {
        state: { data: "pdf", newData: response?.data },
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };

  const handleSearch = async () => {
    try {
      setCardLoading(true);
      const query = { "query-text": text };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/surveyquestion-query
        `,
        query
      );
      setCardLoading(false);
      console.log(response, "surveyquestion");
      localStorage.setItem("form_id", response?.data?.form_id);

      navigate("/surveyQR", {
        state: { data: "query", newData: response?.data },
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };

  return (
    <>
      {showHeading ? (
        <div className="fdaDraft">
          <img
            alt=""
            src={arrow}
            className="arrow-btn"
            onClick={() => navigate(-1)}
          ></img>{" "}
          <div className="heading">
            <div className="heading-title">Generate Survey Form</div>
            <div className="heading-summary">
              Enter a Query or upload a document to generate survey
            </div>
            {/* <div className="heading-support">Supportable file : PDF</div> */}
          </div>
          <TextField
            className="research-searchbar"
            placeholder="Enter Your Query Here to generate a survey"
            variant="outlined"
            multiline={true}
            style={{
              maxHeight: "200px",
              overflowY: "auto",
              // padding: "1%",

              margin: "2% 10%",
              width: "80%",
              backgroundColor: "#ffffff",
              borderRadius: "100px",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "100px",
              },
            }}
            value={text}
            onKeyDown={(e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              } /* else if (e.shiftKey && e.key === "Enter") {
                setMultiline(true);
              } */
            }}
            onChange={(e) => setText(e?.target?.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    style={{
                      borderRadius: "50px",
                      textTransform: "capitalize",
                    }}
                  >
                    {cardLoading ? (
                      <>
                        Generating... &nbsp;
                        <CircularProgress color="inherit" size={20} />{" "}
                      </>
                    ) : (
                      "Generate QR"
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <h1 style={{ display: "flex", justifyContent: "center" }}>OR</h1>
          <div className="upload-section">
            <div className="upload-section-dotted">
              <div>
                <DropzoneArea
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  previewGridProps={{
                    container: { spacing: 1, direction: "row" },
                  }}
                  showAlerts={false}
                  acceptedFiles={[
                    "application/pdf",
                    // "application/vnd.google-apps.document",
                    // "application/vnd.google-apps.spreadsheet",
                    // "application/msword",
                  ]}
                  dropzoneText={
                    <div className="insideDropzone">
                      <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                        Drag and Drop File here or
                      </div>
                      <div style={{ fontSize: "18px", fontStyle: "italic" }}>
                        Supportable file : PDF
                      </div>
                      <Button
                        style={{
                          alignSelf: "center",
                          width: "10vw",
                          fontSize: "1vw",
                          marginTop: "1vw",
                          height: "2.2vw",
                          borderColor: "#153359",
                          borderWidth: "2px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          color: "#001a5c",
                        }}
                      >
                        Browse File
                      </Button>
                    </div>
                  }
                  onDrop={(files) => handleFileUpload(files)}
                  filesLimit={1}
                  maxFileSize={200 * 1024 * 1024}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pdf-page">
          <div className="pdf-section">
            <div className="pdf-heading">Preview of the Uploaded File :</div>
            <div className="pdf-viewer">
              {console.log("pdfviewer", files)}
              <PdfViewer files={files} />
            </div>
          </div>
          <div className="btn-div">
            <Button
              variant="contained"
              className="create-btn"
              style={{ fontSize: "16px", fontWeight: "bold" }}
              onClick={generateQR}
            >
              {qrLoad ? (
                <>
                  Generating... &nbsp;
                  <CircularProgress color="inherit" size={20} />{" "}
                </>
              ) : (
                "Generate QR "
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Survey;
