import apiClient, { setHeader } from "../../utils/apiClient";
import { addSession } from "../../utils/sessionManagement";
import { apiBegin, apiFailure, apiSuccess } from "../action/apiAction";
import {
  deleteBotCall,
  deleteBotFailure,
  deleteBotSuccess,
} from "../action/deleteBotActions";
import { apiConfig } from "../apiConfig/deleteBotApiConfig";

export const deleteBotApiCall = (payload) => (dispatch) => {
  const apiPayload = { ...apiConfig.DELETE };
  apiPayload.data = payload;
  dispatch(apiBegin());
  dispatch(deleteBotCall());
  apiClient(apiPayload)
    .then((res) => {
      addSession(res?.data);
      dispatch(deleteBotSuccess(res));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(deleteBotFailure(err));
      dispatch(apiFailure(err));
    });
};
